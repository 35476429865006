<template>
  <div class="mt-1 mb-5 cardsigualdade">
    <div class="container pl-0 ml-0 text-left">
      <div class="d-flex row flex-md-row flex-md-col flex-wrap h-100">
        <div v-for="(card, i) in data" :key="i" class="card maquina border-0 mt-4 col-md-4">
          <img
            class="img-fluid"
            :src="card.imagem"
            :alt="'Maquina sotreq ' + i"
          />
          <div class="card-body m-0 p-0">
          <a :href="card.link" class="notalink" target="_blank"	>
             <h4 class="mt-4 font-weight-bold">{{ card.titulo }}</h4>
          </a>

          <h5 v-if="!onlytitle" class="">{{ card.texto }}</h5>
          </div>
          <div class="card-footer yellowbottom border-top-0">
          </div> 
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardsIgualdade',
  props: {
    data: {
      type: Array,
      required: true
    },
    onlytitle: {
      type: Boolean,
      default: () => { return false }
    }
  }
}
</script>

<style scoped>
/* The hero image */
/* .maquina{
  cursor: pointer;
} */
.yellowbottom {
    border-bottom: 5px solid #ffcb11;
}
button {
  border-radius: 0;
  border: 3px solid white;
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
}
button:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

/* img {
  overflow: hidden;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  max-height: 95%;
  max-width: 95%;
} */
/* .maquina:hover  {
  color: #ffcb11;
} */
/* .maquina:hover  img{
  overflow: hidden;
  -moz-transform: scale(1.0);
  -webkit-transform: scale(1.0);
  transform: scale(1.0);
} */
.bar {
  margin-top: -20px;
  height: 5px;
  background-color: #ffcb11;
  width: 100px;
}
.traco {
  width: 100px;
  background-color: #ffcb11;
}
.traco.active {
  width: 100px;
  height: 10px;
}

.hero-img { max-height: 70vh; 
  height: calc(100vh - 80px) !important;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

/* Place text in the middle of the image */
.hero-text {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}
</style>
