var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home"},[_c('Header'),_c('HeroMercados',{attrs:{"data":_vm.bannermercado}}),_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col mt-4 text-left"},[_c('h2',{staticClass:"ml-0 pl-0 mt-2 font-weight-bold"},[_vm._v(" "+_vm._s(this.destaqueTitulo)+" ")]),_c('p',{staticClass:"mt-3",staticStyle:{"white-space":"pre-line"}},[_vm._v(" As Ferramentas de Penetração no Solo – FPS, são os seus maiores aliados quando a questão é proteção e produtividade. Devido a natureza de seu trabalho, desgastam-se constantemente, tornando imprescindível o gerenciamento e controle dos seus custos. Na hora de substituir as FPS dos seus equipamentos, opte por peças originais. Você garante a excelente produtividade das suas máquinas. Além de economizar nos custos de operação e manutenção. Com a vantagem das FPS Cat também reduzirem o desgaste das peças estruturais ou de acessórios mais caros. ")]),_c('h2',{staticClass:"ml-0 pl-0 mt-2 font-weight-bold text-yellow"},[_vm._v(" Veja abaixo as opções e escolha a mais adequada para sua operação. ")])])]),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7)]),_c('br'),_c('Compra',{attrs:{"bgcolor":"#f5f5f5"}}),_vm._m(8),_c('h5',{staticClass:"text-center footer-closer",attrs:{"id":"button"}},[_vm._v(" Preencha os formulário abaixo e entraremos em contato com você. ")]),_c('Footer',{attrs:{"bgcolor":"#f5f5f5","buttonless":true,"hasform":true,"gtmterm":"pagina_penetracao_solo"}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"desatque my-4"},[_c('div',{staticClass:"row px-0 mx-0"},[_c('div',{staticClass:"col px-0 mx-0"},[_c('div',{staticClass:"card mb-3"},[_c('div',{staticClass:"row px-0 mx-0 d-flex"},[_c('div',{staticClass:"col-md-5 mx-0 px-0 d-flex align-items-center"},[_c('img',{staticClass:"img-fluid",attrs:{"src":"https://api-site-mig.sotreq.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBaEVHIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--cfd33d414718d30b36a50fad636269208d9a3034/advansys.png "}})]),_c('div',{staticClass:"col-md-7 d-flex flex-column justify-content-center py-1"},[_c('div',{staticClass:"text-left card-body"},[_c('div',{staticClass:"d-flex justify-content-between flex-column"},[_c('div',[_c('h2',{staticClass:"ml-0 pl-0 font-weight-bold"},[_vm._v("Advansys ")]),_c('ul',{staticClass:"pl-2"},[_c('li',[_vm._v("40% a mais de durabilidade nos adaptadores. ")]),_c('li',[_vm._v("20% a mais de durabilidade se comparada à Série K. ")]),_c('li',[_vm._v("75% mais rápida nas trocas de pontas em comparação com outras séries. ")]),_c('li',[_vm._v("Maior segurança para seus operadores e menor tempo de máquina parada. ")]),_c('li',[_vm._v("Ampla variedade de tamanhos e formas. ")])])])])])])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"desatque my-4"},[_c('div',{staticClass:"row px-0 mx-0"},[_c('div',{staticClass:"col px-0 mx-0"},[_c('div',{staticClass:"card mb-3"},[_c('div',{staticClass:"row px-0 mx-0 d-flex flex-md-row-reverse"},[_c('div',{staticClass:"col-md-5 mx-0 px-0 d-flex align-items-center"},[_c('img',{staticClass:"img-fluid",attrs:{"src":"https://api-site-mig.sotreq.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBaElHIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--93b58701ae26cd670d22e2e395b8062587c7ee68/Grupo%20de%20m%C3%A1scara%202.png"}})]),_c('div',{staticClass:"col-md-7 d-flex flex-column justify-content-center py-1"},[_c('div',{staticClass:"text-left card-body"},[_c('div',{staticClass:"d-flex justify-content-between flex-column"},[_c('div',[_c('h2',{staticClass:"ml-0 pl-0 font-weight-bold"},[_vm._v("Série J")]),_c('ul',{staticClass:"pl-2"},[_c('li',[_vm._v("Possibilidade para diversas aplicações de baixo a elevada abrasão e impacto.")]),_c('li',[_vm._v("Disponível em diversos tamanhos e formatos para as mais variadas necessidades.")])])]),_c('div',[_c('h2',{staticClass:"ml-0 pl-0 font-weight-bold"},[_vm._v(" Série J GD (General Duty)")]),_c('ul',{staticClass:"pl-2"},[_c('li',[_vm._v("6 tamanhos de pontas disponíveis em 2 perfis diferentes.")]),_c('li',[_vm._v("Linha de adaptadores J GD exclusivos com compatibilidade com adaptadores da Série J.")]),_c('li',[_vm._v("Recomendadas para condições menos severas de baixo a moderado impacto e abrasividade.")])])])])])])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 my-4"},[_c('h4',{staticClass:"font-weight-bold text-center"},[_vm._v(" Deseja tirar uma dúvida ou fazer uma cotação? ")]),_c('div',{staticClass:"d-flex justify-content-center"},[_c('a',{staticClass:"btn btn-yellow botao",attrs:{"href":"#button","type":"button"}},[_vm._v(" ENTRAR EM CONTATO ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-deck row row-cols-1 row-cols-md-2",attrs:{"data-v-0298ec4e":""}},[_c('div',{staticClass:"mb-4",attrs:{"data-v-0298ec4e":""}},[_c('div',{staticClass:"h-100 card border",attrs:{"data-v-0298ec4e":""}},[_c('img',{staticClass:"card-img-top img-fluid",attrs:{"data-v-0298ec4e":"","src":"https://api-site-mig.sotreq.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBaE1HIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--8251d51a13b249873ee0c564307003e72603c609/pontasK.png","alt":"Sotreq revendedora Caterpillar"}}),_c('div',{staticClass:"card-body text-left",attrs:{"data-v-0298ec4e":""}},[_c('h2',{staticClass:"ml-0 pl-0 font-weight-bold"},[_vm._v(" Pontas K ")]),_c('ul',{staticClass:"pl-2"},[_c('li',[_vm._v("Projetado para ampla gama de aplicações de baixa a elevada abrasão e impacto. ")]),_c('li',[_vm._v("Disponível em diversos tamanhos e formatos para mais variadas aplicações.")])])])])]),_c('div',{staticClass:"mb-4",attrs:{"data-v-0298ec4e":""}},[_c('div',{staticClass:"h-100 card border",attrs:{"data-v-0298ec4e":""}},[_c('img',{staticClass:"card-img-top img-fluid",attrs:{"data-v-0298ec4e":"","src":"https://api-site-mig.sotreq.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBaFFHIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--02c75cca0a5be967f3c6fbc47274eb8c2025bfb7/416.png","alt":"Sotreq revendedora Caterpillar"}}),_c('div',{staticClass:"card-body text-left",attrs:{"data-v-0298ec4e":""}},[_c('h2',{staticClass:"ml-0 pl-0 font-weight-bold"},[_vm._v(" Ponta DRT para Retro ")]),_c('ul',{staticClass:"pl-2"},[_c('li',[_vm._v("Pontas mais robusta feita para elevar a produtividade da sua retroescavadeira com uma ótima relação de custo/benefício. Aplicável para as mais diversas operações.")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 my-4"},[_c('h4',{staticClass:"font-weight-bold text-center"},[_vm._v(" Deseja tirar uma dúvida ou fazer uma cotação? ")]),_c('div',{staticClass:"d-flex justify-content-center"},[_c('a',{staticClass:"btn btn-yellow botao",attrs:{"href":"#button","type":"button"}},[_vm._v(" ENTRAR EM CONTATO ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"desatque my-4"},[_c('div',{staticClass:"row px-0 mx-0"},[_c('div',{staticClass:"col px-0 mx-0"},[_c('div',{staticClass:"card mb-3"},[_c('div',{staticClass:"row px-0 mx-0 d-flex"},[_c('div',{staticClass:"col-md-5 mx-0 px-0 d-flex align-items-center"},[_c('img',{staticClass:"img-fluid",attrs:{"src":"https://api-site-mig.sotreq.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBaFVHIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--ff0329447df6137dcc3e0b52072c0237c64841b0/motoniveladora.png"}})]),_c('div',{staticClass:"col-md-7 d-flex flex-column justify-content-center py-1"},[_c('div',{staticClass:"text-left card-body"},[_c('div',{staticClass:"d-flex justify-content-between flex-column"},[_c('div',[_c('h2',{staticClass:"ml-0 pl-0 font-weight-bold"},[_vm._v("Lâminas de motoniveladora ")]),_c('ul',{staticClass:"pl-2"},[_c('li',[_vm._v("Possibilidades para operações que buscam melhor custo benefício e maior produtividade para a sua máquina Caterpillar. ")]),_c('li',[_vm._v("Alta gama de possibilidades para os mais diferentes tipos de aplicações. ")]),_c('li',[_vm._v("Opções de baixo a alto impacto e abrasão.")])])])])])])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"desatque my-4"},[_c('div',{staticClass:"row px-0 mx-0"},[_c('div',{staticClass:"col px-0 mx-0"},[_c('div',{staticClass:"card mb-3"},[_c('div',{staticClass:"row px-0 mx-0 d-flex flex-md-row-reverse"},[_c('div',{staticClass:"col-md-5 mx-0 px-0 d-flex align-items-center"},[_c('img',{staticClass:"img-fluid",attrs:{"src":"https://api-site-mig.sotreq.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBaFlHIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--247ab66c2fbd6ba54d0141ae561315787a14035e/ca%C3%A7amba.png"}})]),_c('div',{staticClass:"col-md-7 d-flex flex-column justify-content-center py-1"},[_c('div',{staticClass:"text-left card-body"},[_c('div',{staticClass:"d-flex justify-content-between flex-column"},[_c('div',[_c('h2',{staticClass:"ml-0 pl-0 font-weight-bold"},[_vm._v("Segmentos e Protetores de caçamba ")]),_c('ul',{staticClass:"pl-2"},[_c('li',[_vm._v("Com foco no equilíbrio entre produtividade, perfuração e proteção, nossos segmentos e protetores são referencias para quem busca custo/beneficio e produtividade. ")]),_c('li',[_vm._v("Alta gama de opções para as mais diversas aplicações.")])])])])])])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row my-5"},[_c('div',{staticClass:"col text-left"},[_c('h2',{staticClass:"font-weight-bold"},[_vm._v("Informações sobre garantia")]),_c('div',{staticClass:"bar mb-5"}),_c('p',{staticClass:"preline"},[_vm._v("Geralmente, a garantia para peças compradas em "),_c('a',{attrs:{"target":"_blank","href":""}},[_vm._v(" Loja on-line de Peças ")]),_vm._v("2 é de 12 meses a partir da data da fatura, conforme definido na Garantia Limitada da Caterpillar. A Garantia das Peças abrange defeitos no material e de fabricação durante todo o período de Garantia uma vez instalada. Para obter todos os detalhes sobre a Garantia, entre em contato com a Sotreq.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h2',{staticClass:"text-center font-weight-bold my-5"},[_vm._v(" FAÇA A ESCOLHA CERTA E ENTRE "),_c('br'),_vm._v(" EM CONTATO COM A SOTREQ ")])
}]

export { render, staticRenderFns }