<template>
  <div class="home">
    <Header />
    <HeroMercados :data="bannermercado" />
    <div class="container">
      <div class="row">
        <div class="col mt-4">
          <h1 class="ml-0 pl-0 mt-2 font-weight-bold">
            {{ this.destaqueTitulo }}
          </h1>
          <p style="white-space: pre-line" class="mt-3">
            {{ this.destaqueTexto }}
          </p>
        </div>
      </div>
      <div class="container">
        <div class="desatque my-4">
          <div class="row px-0 mx-0">
            <div class="col px-0 mx-0">
              <div class="card mb-3">
                <div class="row px-0 mx-0 d-flex flex-md-row-reverse">
                  <div class="col-md-5 mx-0 px-0 d-flex align-items-center">
                    <img
                      src="https://api-site-mig.sotreq.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBcm9DIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--692b65f49dfbb8f1562ee1a85aa2dff0d27c1d90/Imagem%2031.png"
                      class="img-fluid"
                    />
                  </div>
                  <div
                    class="
                      col-md-7
                      d-flex
                      flex-column
                      justify-content-center
                      py-1
                    "
                  >
                    <div class="text-left card-body">
                      <div class="d-flex justify-content-between flex-column">
                        <div>
                          <h2 class="">Montagem rápida</h2>
                          <div class="bar mb-1"></div>
                          <p class="mb-3">
                            Você pode comprar desmontadas ou montadas. A Sotreq
                            oferece soluções de mangueiras hidráulicas
                            superiores para QUALQUER marca de equipamento.
                            Nossos técnicos especializados localizados em mais
                            de 20 filiais espalhadas pelo Brasil farão
                            mangueiras e acoplamentos personalizados no local
                            para que você e sua máquina possam voltar ao
                            trabalho.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="desatque my-4">
          <div class="row px-0 mx-0">
            <div class="col px-0 mx-0">
              <div class="card mb-3">
                <div class="row px-0 mx-0 d-flex">
                  <div class="col-md-5 mx-0 px-0 d-flex align-items-center">
                    <img
                      src="https://api-site-mig.sotreq.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBcnNDIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--1a1d002417a8648fd8e935c0a9e13a71c7917d55/Grupo%20de%20m%C3%A1scara%209.png"
                      class="img-fluid"
                    />
                  </div>
                  <div
                    class="
                      col-md-7
                      d-flex
                      flex-column
                      justify-content-center
                      py-1
                    "
                  >
                    <div class="text-left card-body">
                      <div class="d-flex justify-content-between flex-column">
                        <div>
                          <h2 class="">Benefícios</h2>
                          <div class="bar mb-1"></div>
                          <p class="mb-3">
                            Produtos de alto desempenho para as operações mais
                            simples até as mais severas.<br />
                            Resistência 20 vezes maior à abrasão comparadas à
                            outras mangueiras cobertas com borracha.<br />
                            Trabalham com metade do raio de dobramento da SAE
                            sem sacrificar a capacidade de flexibilidade a
                            frio.<br />
                            Confiabilidade da Caterpillar, único fabricante de
                            equipamentos a produzir as suas próprias mangueiras
                            e terminais.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <h4 class="text-center">
          Saiba mais sobre a qualidade das Mangueiras Hidráulicas Cat
        </h4>
        <div class="embed-responsive embed-responsive-16by9">
          <iframe
            class="embed-responsive-item"
            src="https://www.youtube.com/embed/iizqppy5_cQ"
          ></iframe>
        </div>
        <div
          class="d-flex flex-column flex-md-row justify-content-between mt-5"
        >
          <a
            href="https://parts.cat.com/pt/sotreq"
            type="button"
            class="btn btn-secondary btn-black btn-lg btn-block notalinkwhite"
          >
            Parts.cat</a
          >
          <a
            type="button"
            class="btn btn-secondary btn-lg btn-black btn-block notalinkwhite"
            href="#/filiais"
            >Locais com lojas de mangueiras</a
          >
          <a
            type="button"
            class="btn btn-secondary btn-lg btn-block btn-black notalinkwhite"
            href="#/contato"
            >Fale conosco</a
          >
        </div>
        <h1 class="text-center text-yellow text-uppercase mt-5">
          É POR ISSO QUE A CATEPILLAR OFERECE SOLUÇÕES DE MANGUEIRA SUPERIORES
        </h1>
      </div>
      <div class="container mt-5">
        <div class="row mb-5">
          <div class="col mt-4 text-left">
            <h2 class="">Fabricação rigorosa</h2>
            <div class="bar mb-1"></div>
            <h4 class="mt-4">
              Conjuntos de mangueiras construídos com as especificações exatas
              da Cat.
            </h4>
            <p class="mb-3">
              Nossas soluções de montagem de mangueiras hidráulicas são
              desenvolvidas de acordo com as especificações novas para
              equipamentos Cat. Isso significa que cada novo conjunto de
              mangueira corresponde perfeitamente à versão instalada de fábrica
              que está sendo substituida.
            </p>
          </div>
        </div>
        <div class="row row-cols-1 row-cols-md-2">
          <div class="col mb-4">
            <div class="card bg-black">
              <div class="row no-gutters">
                <div class="col-md-4">
                  <img
                    src=" https://api-site-mig.sotreq.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBcndDIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--75b2febbddfecbc0b83c7c075e0f8dc40ca12de0/Imagem%201.png "
                    alt="Sotreq revendedora Caterpillar"
                    class="img-fluid"
                  />
                </div>
                <div class="col-md-8">
                  <div class="card-body">
                    <p class="card-text text-white text-left">
                      Aplicamos o controle estatístico do processo para
                      verificar se cada lote de borracha foi misturado de acordo
                      com nossas especificações exatas
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col mb-4">
            <div class="card bg-black">
              <div class="row no-gutters">
                <div class="col-md-4">
                  <img
                    src="https://api-site-mig.sotreq.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBcjBDIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--49f5806301928970aa2f619dec0170b4bbbf5448/Imagem%203.png"
                    alt="Sotreq revendedora Caterpillar"
                    class="img-fluid"
                  />
                </div>
                <div class="col-md-8">
                  <div class="card-body">
                    <p class="card-text text-left text-white">
                      Nossos acoplamentos são forjados e usinados em aço de alta
                      resistência à tração. Em seguida, eles são tratados
                      termicamente e banhados para garantir a máxima
                      durabilidade e resistência à corrosão.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col mb-4">
            <div class="card bg-black">
              <div class="row no-gutters">
                <div class="col-md-4">
                  <img
                    src="https://api-site-mig.sotreq.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBcjRDIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--f73ac02aca32c3ea2042b1a2735d12316150a1c2/Imagem%204.png"
                    alt="Sotreq revendedora Caterpillar"
                    class="img-fluid"
                  />
                </div>
                <div class="col-md-8">
                  <div class="card-body">
                    <p class="card-text text-left text-white">
                      A mangueira Cat é cuidadosamente monitorada para garantir
                      que as medições precisas dos diâmetros interno e externo
                      sejam mantidas. Isso fornece capacidade de retenção de
                      acoplamento precisa.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col mb-4">
            <div class="card bg-black">
              <div class="row no-gutters">
                <div class="col-md-4">
                  <img
                    src="https://api-site-mig.sotreq.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBc0FDIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--da025adde6926c5d1f30b50559f3cedc382815d8/Grupo%20de%20m%C3%A1scara%202.png"
                    alt="Sotreq revendedora Caterpillar"
                    class="img-fluid"
                  />
                </div>
                <div class="col-md-8">
                  <div class="card-body">
                    <p class="card-text text-left text-white">
                      Antes da aplicação, a mangueira é encaminhada por uma
                      câmara de resfriamento até atingir uma temperatura
                      adequada. Isso evita a perfuração da mangueira quando o
                      fio de reforço de alta resistência à tração é aplicado.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="desatque my-4">
          <div class="row px-0 mx-0">
            <div class="col px-0 mx-0">
              <div class="card mb-3 bg-yellow">
                <div class="row px-0 mx-0 d-flex flex-md-row-reverse">
                  <div class="col-md-5 mx-0 px-0 d-flex align-items-center">
                    <img
                      src="https://api-site-mig.sotreq.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBc0VDIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--73528b2dadc5fb9e629b71daa4a41e7a34676280/Imagem%2018.png"
                      class="img-fluid"
                    />
                  </div>
                  <div
                    class="
                      col-md-7
                      d-flex
                      flex-column
                      justify-content-center
                      py-1
                    "
                  >
                    <div class="text-left card-body">
                      <div class="d-flex justify-content-between flex-column">
                        <div>
                          <h2 class="">
                            HARDWARE E SOFTWARE INTEGRADOS PARA GARANTIR UMA
                            MONTAGEM CONFIÁVEL E SEGURA.
                          </h2>
                          <p class="mb-3">
                            A Sotreq está equipada com ferramentas e materiais
                            de ultima geração para entregar uma conexão
                            perfeita.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CardsTransmissao :titleless="true" :data="this.cards" />
    </div>
    <a href="/#/contato">
      <button type="button" class="px-5 btn btn-secondary">Fale conosco</button>
    </a>
    <div class="container mt-5">
      <div class="row">
        <div class="col mt-4 text-left">
          <h2 class="">Terminais reutilizáveis Cat.</h2>
          <div class="bar mb-1"></div>
          <br />
        </div>
      </div>
      <div class="row row-cols-1 row-cols-md-1">
        <div class="col mb-4">
          <div class="card">
            <div class="row no-gutters align-items-center">
              <div class="col-md-2">
                <img
                  src="https://api-site-mig.sotreq.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBc0lDIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--c8e3dbbd05e5ff7bd5ac0371a19e4e12534394c2/Grupo%20de%20m%C3%A1scara%208.png"
                  alt="Sotreq revendedora Caterpillar"
                  class="img-fluid my-auto"
                />
              </div>
              <div class="col-md-10">
                <div class="card-body">
                  <p class="card-text text-left">
                    MANTENHA-O <br />
                    Não há necessidade de comprar um novo acoplamento cada vez
                    que uma mangueira é substituída. E não há limite específico
                    para o número de vezes que um acoplamento pode ser
                    reutilizado.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col mb-4">
          <div class="card">
            <div class="row no-gutters align-items-center">
              <div class="col-md-2">
                <img
                  src="https://api-site-mig.sotreq.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBc01DIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--475bbbc40d082ebb738d33737fff50afbb739bb3/Grupo%20de%20m%C3%A1scara%207.png"
                  alt="Sotreq revendedora Caterpillar"
                  class="img-fluid my-auto"
                />
              </div>
              <div class="col-md-10">
                <div class="card-body">
                  <p class="card-text text-left">
                    ECONOMIZE TEMPO <br />
                    Com os acoplamentos reutilizáveis CAT®, um estoque pronto
                    está sempre disponível e o reabastecimento imediato ocorre
                    no momento da recuperação, reduzindo o tempo de inatividade
                    da máquina.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col mb-4">
          <div class="card">
            <div class="row no-gutters align-items-center">
              <div class="col-md-2">
                <img
                  src=" https://api-site-mig.sotreq.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBc1FDIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--2563aa89a243e9f9d97a3ce70fc7e03f3a794f74/Grupo%20de%20m%C3%A1scara%206.png "
                  alt="Sotreq revendedora Caterpillar"
                  class="img-fluid my-auto"
                />
              </div>
              <div class="col-md-10">
                <div class="card-body">
                  <p class="card-text text-left">
                    ECONOMIZE DINHEIRO<br />
                    Cada troca você pode economizar 30% ou mais no custo de
                    montagens comparados com acoplamentos permanentes.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col mb-4">
          <div class="card">
            <div class="row no-gutters align-items-center">
              <div class="col-md-2">
                <img
                  src=" https://api-site-mig.sotreq.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBc1VDIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--15ce60bfd81015464f8bf0c445d955087777a0a1/Grupo%20de%20m%C3%A1scara%205.png "
                  alt="Sotreq revendedora Caterpillar"
                  class="img-fluid my-auto"
                />
              </div>
              <div class="col-md-10">
                <div class="card-body">
                  <p class="card-text text-left">
                    CONSTRUÍDO PARA DURAR<br />
                    Os acoplamentos reutilizáveis CAT® para mangueiras XT ™ são
                    projetados contra falhas, sem limite no número de vezes que
                    podem ser usados.<br />
                    <small>
                      *Terminais disponíveis acima de 1 pol.<br />
                      * Não inclui abuso, negligência, incêndio, uso impróprio,
                      instalação ou reparo.</small
                    >
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="desatque my-4">
        <div class="row px-0 mx-0">
          <div class="col px-0 mx-0">
            <div class="card mb-3 bg-yellow">
              <div class="row px-0 mx-0 d-flex">
                <div class="col-md-5 mx-0 px-0 d-flex align-items-center">
                  <img
                    src="https://api-site-mig.sotreq.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBc1lDIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--68e26fb9102870eac8a76e1d88bd2e2e55aa85a3/Grupo%20de%20m%C3%A1scara%204.png"
                    class="img-fluid"
                  />
                </div>
                <div
                  class="
                    col-md-7
                    d-flex
                    flex-column
                    justify-content-center
                    py-1
                  "
                >
                  <div class="text-left card-body">
                    <div class="d-flex justify-content-between flex-column">
                      <div>
                        <h4 class="mb-3">
                          EXCLUSIVAMENTE PROJETADO E TOTALMENTE APROVADO.
                        </h4>
                        <h2 class="mb-3">
                          TENHA CONFIANÇA E SAIBA QUE PODE CONFIAR NOS
                          ACOPLAMENTOS REUTILIZÁVEIS CAT
                        </h2>
                        <p>
                          É por isso que os acoplamentos reutilizáveis CAT são
                          testados, validados e garantidos para durar com a
                          mangueira XT™.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="card bg-dark text-white w-100 position-relative mb-5"
      style="
        background-image: url(https://api-site-mig.sotreq.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBc2NDIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--812bd1577ab2d9bf8f693e4d71c9be5b87e9810f/MMMM.png);
        height: 100%;
        background-size: cover;
        background-position: center;
      "
    >
      <div class="card-img-overlay">
        <div class="container">
          <h1 class="card-title text-yellow text-left mb-5">
            Compatibilidade completa
          </h1>
          <p class="card-text text-white text-left">
            As mangueiras Cat não são apenas para máquinas Caterpillar. Elas
            oferecem desempenho, confiabilidade e durabilidade incomparáveis
            para todas as marcas, incluindo:
          </p>
          <div class="row my-5">
            <div class="col-md-2">
              <h4 class="text-white text-left">
                Volvo<br />
                Komatsu<br />
                Doosan<br />
                John Deere<br />
              </h4>
            </div>
            <div class="col-md-2">
              <h4 class="text-white text-left">
                Hitachi<br />
                JCB<br />
                CASE<br />
                E MUITO MAIS!
              </h4>
            </div>
            <div class="col-12 col-md-8">&nbsp;</div>
          </div>
          <p class="card-text text-white text-left">
            Qualquer equipamento com que você esteja trabalhando, a Sotreq pode
            montar mangueiras hidráulicas de precisão para manter seu
            equipamento funcionando.
          </p>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header.vue";
import HeroMercados from "@/components/Mercados/HeroMercados.vue";
import Footer from "@/components/Footer.vue";
import ApiContent from "@/services/api.js";
import CardsTransmissao from "../components/CardsTransmissao.vue";

export default {
  name: "ProdutosMangueiras",
  mounted() {
    this.loadContent();
  },
  components: {
    Header,
    HeroMercados,
    Footer,
    CardsTransmissao,
  },
  methods: {
    async loadContent() {
      const resp = await ApiContent.get("paginas/140.json");
      console.log(resp.data);
      this.heroTopTitulo = resp.data.heroTopTitulo;
      this.heroTopTexto = resp.data.heroTopTexto;
      this.heroTopLink = resp.data.heroTopLink;
      this.destaqueTitulo = resp.data.destaqueTitulo;
      this.destaqueTexto = resp.data.destaqueTexto;
      this.destaqueChamada = resp.data.destaqueChamada;
      this.textoBaseTitulo = resp.data.textoBaseTitulo;
      this.textoBaseTexto = resp.data.textoBaseTexto;
      this.heroBottomTitulo = resp.data.heroBottomTitulo;
      this.heroBottomTexto = resp.data.heroBottomTexto;
      this.heroBottomLink = resp.data.heroBottomLink;
      this.bottomherobuttontext = resp.data.bottomherobuttontext;
      this.heroBottomLink = resp.data.heroBottomLink;
      this.url = resp.data.url;
      this.topHeroImage = resp.data.topHeroImage;
      this.logo = resp.data.logo;
      this.bottomHeroImage = resp.data.bottomHeroImage;
      this.cards = resp.data.cards;
          this.bannermercado = {
        titulo: resp.data.heroTopTitulo,
        image: resp.data.topHeroImage,
        subtitulo: resp.data.subtitulo,
        texto: resp.data.texto,
        link: resp.data.link,
        buttontext: resp.data.buttontext,
        herocontent: resp.data.herocontent
      };
      //this.cards.map(card => {
      //  card.link = '#/acessorio-inner/' + card.link
      // })
    },
  },
  data() {
    return {
      heroTopTitulo: [],
      heroTopTexto: [],
      heroTopLink: [],
      destaqueTitulo: [],
      destaqueTexto: [],
      destaqueChamada: [],
      textoBaseTitulo: [],
      textoBaseTexto: [],
      heroBottomTitulo: [],
      heroBottomTexto: [],
      heroBottomLink: [],
      created_at: [],
      updated_at: [],
      url: [],
      topHeroImage: [],
      logo: [],
      bottomHeroImage: [],
      cards: [],
    };
  },
};
</script>
<style scoped>
.card {
  border: none;
}
.quadrado {
  border: 5px solid #ffcb11;
}
.equipamento_nvo {
  background: rgb(2, 0, 36);
  background: radial-gradient(
    circle,
    rgba(2, 0, 36, 1) 0%,
    rgba(214, 155, 31, 1) 50%
  );
}
a {
  text-decoration: none;
}
button {
  border: 2px solid white;
  background-color: black;
  color: white;
}

button:hover {
  border: 2px solid black;
  background-color: white;
  color: black;
}

div.card:nth-child(6) > img:nth-child(1) {
  min-height: 450px;
}

.row {
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.card-body {
  align-items: center;
  display: flex;
}
.card-img-overlay {
  position: relative !important;
}
@media (min-width: 648px) {
  .btn-block + .btn-block {
    margin-top: unset;
  }
}
</style>
