<template>
  <div class="home">
    <Header />
    <div data-v-241e4969="" data-v-4f540693="" class="herohome">
      <div data-v-241e4969="" class="d-block d-sm-block d-md-block d-lg-none">
        <img
          data-v-241e4969=""
          src="https://api-site-mig.sotreq.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBbVFHIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--de136505d4a80bc368e1dcc8c14f877751cbef9d/heroFilial2mob.png"
          alt="HeroMercados"
          class="img-fluid"
        />
        <div
          data-v-241e4969=""
          class="container my-5 my-md-0 d-flex align-items-center text-left h-100"
        >
          <div data-v-241e4969="" class="text-black text-left">
            <h1 data-v-241e4969="" class="font-weight-bold display-4">
              Filiais Sotreq
              <!---->
            </h1>
            <div data-v-241e4969="" class="bar"></div>
          </div>
        </div>
      </div>
      <div data-v-241e4969="" class="d-none d-sm-none d-md-none d-lg-block">
        <div data-v-241e4969="" class="hero-overlay">&nbsp;</div>
        <div
          data-v-241e4969=""
          class="hero-img active"
          style="
            background-image: url(https://api-site-mig.sotreq.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBbU1HIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--dd6a3ec6c24d0d5392d8bce9f531acb6a1da7907/heroFilial2.png);
          "
        >
          <div
            data-v-241e4969=""
            class="container d-flex align-items-center text-left h-100 p-0"
          >
            <div data-v-241e4969="" class="col-7 text-white text-left">
              <h1 data-v-241e4969="" class="display-4 font-weight-bold lh-sm">
                Filiais Sotreq<!---->
              </h1>
              <div data-v-241e4969="" class="bar mt-1"></div>
              <div data-v-241e4969=""><!----><!----></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="#/filiais">Filiais</a></li>
          <li class="breadcrumb-item active" aria-current="page">
            {{ location.nome }} - {{ location.estado }}
          </li>
        </ol>
      </nav>
      <div class="container">
        <div class="row my-3">
          <div class="col-12 col-lg-5 text-left">
            <h2 class="font-weight-bold">
              {{ location.nome }} - {{ location.estado }}
            </h2>
            <p class="font-weight-bold mb-0">Endereço:</p>
            <p>{{ location.endereco }} CEP: {{ location.cep }}</p>
            <p class="font-weight-bold mb-0">Telefone:</p>
            <p>{{ location.telefone }}</p>

            <div v-if="location.topcontent?.length > 1">
              <div class="resetforcontent">
                <div v-html="location.topcontent"></div>
              </div>
            </div>

            <div></div>
          </div>
          <div class="col-12 col-lg-7 mapcontainer" v-if="renderComponent">
            <div id="map" class="map"></div>
          </div>
        </div>
      </div>
      <div class="row mt-1 mb-4">
        <div class="col-6 col-md-3 col-lg-5 d-flex justify-content-start">
          <a class="btn btn-warning" href="#/contato">@ Fale Conosco</a>
        </div>
        <div
          class="col-6 col-md-9 col-lg-7 d-flex justify-content-start"
          v-if="location.trajeto != null && location.trajeto != undefined"
        >
          <a
            class="btn btn-primary text-white"
            :href="location.trajeto"
            target="_blank"
            ><i class="bi bi-map-fill"></i> Trajetos</a
          >
        </div>
      </div>

      <h5 class="text-left font-weight-bold">Serviços prestados pela filial</h5>
      <div
        v-if="
          location.bottomcontent == null && location.bottomcontent == undefined
        "
      >
        <ul class="text-left" v-for="(item, i) in location.servicos" :key="i">
          <li>{{ item.name }}</li>
        </ul>
      </div>

      <div v-if="location.bottomcontent?.length > 1">
        <div class="resetforcontent">
          <div v-html="location.bottomcontent"></div>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header.vue";
import HeroMercados from "@/components/Mercados/HeroMercados.vue";
import Footer from "@/components/Footer.vue";
import Novidade from "@/components/Novidade.vue";
import ApiContent from "@/services/api.js";

export default {
  name: "FilialDetalhe",
  async mounted() {
    window.scrollTo(0, 0);
    this.loadCategoria();

    this.loadPagecontent();
  },
  components: {
    Header,
    HeroMercados,
    Footer,
    Novidade,
  },
  methods: {
    async loadPagecontent() {
      const response = await ApiContent.get("paginas/179.json");
      console.log(response.data);
      this.heroTopTitulo = response.data.heroTopTitulo;
      this.heroTopTexto = response.data.heroTopTexto;
      this.heroTopLink = response.data.heroTopLink;
      this.destaques = response.data.destaques;
      this.destaqueTitulo = response.data.destaqueTitulo;
      this.destaqueTexto = response.data.destaqueTexto;
      this.destaqueChamada = response.data.destaqueChamada;
      this.textoBaseTitulo = response.data.textoBaseTitulo;
      this.textoBaseTexto = response.data.textoBaseTexto;
      this.heroBottomTitulo = response.data.heroBottomTitulo;
      this.heroBottomTexto = response.data.heroBottomTexto;
      this.heroBottomLink = response.data.heroBottomLink;
      this.bottomherobuttontext = response.data.bottomherobuttontext;
      this.heroBottomLink = response.data.heroBottomLink;
      this.url = response.data.url;
      this.topHeroImage = response.data.topHeroImage;
      this.logo = response.data.logo;
      this.bottomHeroImage = response.data.bottomHeroImage;
      this.conteudo = response.data.content;
      this.cards = response.data.cards;
      this.bannermercado = {
        titulo: response.data.heroTopTitulo,
        image: response.data.topHeroImage,
        subtitulo: response.data.subtitulo,
        texto: response.data.texto,
        link: response.data.link,
        buttontext: response.data.buttontext,
        herocontent: response.data.herocontent,
      };
    },
    async loadCategoria() {
      const resp = await ApiContent.get(
        `/filiais/${this.$route.params.id}.json`
      );
      this.location = {
        id: resp.data.id,
        nome: resp.data.nome,
        estado: resp.data.estado,
        endereco: resp.data.endereco,
        cep: resp.data.cep,
        telefone: resp.data.telefone,
        topcontent: resp.data.top_content,
        bottomcontent: resp.data.bottom_content,
        servicos: resp.data.servicos,
        trajeto: resp.data.email,
        lat: resp.data.lat,
        lon: resp.data.lon,
      };
      L.mapbox.accessToken =
        "pk.eyJ1Ijoia2VudGFyb2Z1aml5IiwiYSI6ImNqYzJjemZkMDBkZDIzNHI1MTdvOHE0eGgifQ.lFB0RUWtS_pJQR9Ne4QlFA";

      this.map = L.mapbox
        .map("map")
        .setView(
          [parseFloat(this.location.lon), parseFloat(this.location.lat)],
          13
        );

      L.mapbox.styleLayer("mapbox://styles/mapbox/streets-v11").addTo(this.map);

      L.marker([parseFloat(this.location.lon), parseFloat(this.location.lat)])
        .addTo(this.map)
        .bindPopup(this.location.endereco)
        .openPopup();
    },
    forceRerender() {
      // Remove my-component from the DOM
      this.renderComponent = false;

      this.$nextTick(() => {
        // Add the component back in
        this.renderComponent = true;
      });
    },
    makeMySearch() {
      this.loadCategoria();
      this.loadPagecontent();
      this.forceRerender();
    },
  },
  data() {
    return {
      renderComponent: true,
      heroTopTitulo: [],
      heroTopTexto: [],
      heroTopLink: [],
      destaqueTitulo: [],
      destaqueTexto: [],
      destaques: [],
      destaqueChamada: [],
      textoBaseTitulo: [],
      textoBaseTexto: [],
      heroBottomTitulo: [],
      heroBottomTexto: [],
      heroBottomLink: [],
      created_at: [],
      updated_at: [],
      url: [],
      topHeroImage: [],
      logo: [],
      bottomHeroImage: [],
      cards: [],
      conteudo: [],
      bannermercado: [],
      location: [],
      trajeto: []
    };
  },
};
</script>
<style scoped>
.breadcrumb {
  background-color: transparent;
}
.quadrado {
  border: 5px solid #ffcb11;
}

.equipamento_nvo {
  background: rgb(2, 0, 36);
  background: radial-gradient(
    circle,
    rgba(2, 0, 36, 1) 0%,
    rgba(214, 155, 31, 1) 50%
  );
}

a {
  text-decoration: none;
}

button {
  border-radius: 0;
  border: 3px solid white;
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
}

button:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.bar {
  margin-top: -20px;
  height: 5px;
  background-color: #ffcb11;
  width: 100px;
}

.traco {
  width: 100px;
  background-color: #ffcb11;
}

.traco.active {
  width: 100px;
  height: 10px;
}

.hero-img {
  max-height: 70vh;
  height: 700px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

/* Place text in the middle of the image */
.hero-text {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}

.herohome {
  margin-top: -80px;
}

.yellowbar {
  width: 100%;
  height: 12px;
  background-color: #ffcd11;
}

.greybar {
  width: 100%;
  height: 131px;
  background-color: #ececec;
}

.greybar {
  width: 100%;
  height: 100%;
  background-color: #ececec;
}

.black-bg {
  background-color: #000;
}

.separatorbox {
  min-height: 100%;
  border-right: solid 1px #000;
  margin: 0px 1em 0px 1em;
}

.plus-drop {
  width: 1.5em;
}

.spec-collapse {
  border-top: solid 0.5px #000;
}

.last-line {
  min-width: 100%;
  border-top: solid 1px #000;
}

a.w-100[aria-expanded="false"]
  div.d-flex.justify-content-between.spec-collapse.p-3
  img.plus-drop {
  transform: rotate(0deg);
  transition: transform 1s;
}

a.w-100[aria-expanded="true"]
  div.d-flex.justify-content-between.spec-collapse.p-3
  img.plus-drop {
  transform: rotate(45deg);
  transition: transform 1s;
}

.position-absolute.w-100.h-100.preloader {
  background-color: #fff;
  z-index: 99999;
  position: absolute;
  margin: auto;
  padding-top: 35vh;
}

.sidebar {
  position: relative;
  width: 33.3333%;
  height: 100%;
  overflow: hidden;
  border-right: 1px solid rgba(0, 0, 0, 0.25);
}

.pad2 {
  padding: 20px;
}

.quiet {
  color: #888;
}

.map {
  position: relative;
  width: 358px;
  min-width: 358px;
  max-width: 358px;
  height: 285px;
  min-height: 285px;
  max-height: 285px;
}

.heading {
  background: #fff;
  border-bottom: 1px solid #eee;
  min-height: 60px;
  line-height: 60px;
  padding: 0 10px;
}

::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  border-left: 0;
  background: rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar-track {
  background: none;
}

::-webkit-scrollbar-thumb {
  background: #00853e;
  border-radius: 0;
}

.clearfix {
  display: block;
}

.clearfix:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

/* Marker tweaks */
.leaflet-popup-close-button {
  display: none;
}

.leaflet-popup-content {
  font: 400 15px/22px "Source Sans Pro", "Helvetica Neue", Sans-serif;
  padding: 0;
  width: 200px;
}

.leaflet-popup-content-wrapper {
  padding: 0;
}

.leaflet-popup-content h3 {
  background: yellow;
  color: #000;
  margin: 0;
  display: block;
  padding: 10px;
  border-radius: 3px 3px 0 0;
  font-weight: 700;
  margin-top: -15px;
}

.leaflet-popup-content div {
  padding: 10px;
}

.leaflet-container .leaflet-marker-icon {
  cursor: pointer;
}

a {
  text-decoration: none;
  color: black;
}

button {
  border: 2px solid white;
  background-color: black;
  color: white;
}

button:hover {
  border: 2px solid black;
  background-color: white;
  color: black;
}

.btnsend {
  text-transform: uppercase;
  background-color: #f7b220;
  border: #f7b220;
  color: black;
  border-radius: 0.2em;
}

.btnsend:hover {
  color: #f7b220;
  background-color: #000;
}
@media (min-width: 768px) {
  .map {
    width: 100%;
    min-width: 100%;
    max-width: 379px;
  }
    .herohome {
    margin-top: -101px;
}
}
@media (min-width: 1280px) {
  .map {
    position: relative;
    width: 668px;
    min-width: 668px;
    max-width: 668px;
    height: 354px;
    min-height: 354px;
    max-height: 354px;
  }
}
</style>
