<template>
<div>
     <div class="d-none d-md-none d-lg-block d-xl-block">

    <div class="collapse" id="collapseExample">
      <div class="w-100 card card-body">

        <div class="row">

          <div class="py-2 col-12 pb-4">
            <div id="searchbox"></div>
          </div>
          <div class=" col-0 col-lg-4" id="deskleft">
      
              <h5 class="font-weight-bold text-left mb-3 hbartitle">
                Mais pesquisados:
              </h5>
            <div class="row row-cols-1 row-cols-md-2">
              <div class="col">
                <a href="/#/nossasmaquinas" class="notalink card border-0">
                  <img
                    src="https://api-site-mig.sotreq.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBbmdHIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--9441c1d96a127b26d150019a0aa356868f285752/maquinas.png"
                    class="card-img-top" alt="...">
                  <div class="card-body p1rem">
                    <h5 class="card-title text-center">Máquinas</h5>
                  </div>
                </a>
              </div>
              <div class="col">
                <a href="/#/tecnologia/solucoes-sotreqlink" class="notalink card border-0">
                  <img
                    src="https://api-site-mig.sotreq.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBbnNHIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--bfcfaade366ac1ce4a5627d636848b3f1bf394a4/sotreqlink.png"
                    class="card-img-top" alt="...">
                  <div class="card-body p1rem">
                    <h5 class="card-title text-center">SotreqLink</h5>
                  </div>
                </a>
              </div>
              <div class="col">
                <a href="https://sotreqseminovos.com.br" class="notalink card border-0" target="_blank">
                  <img
                    src="https://api-site-mig.sotreq.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBbndHIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--6ef3f2bc363629e9cd5b9cae42e03f4388f09657/seminovos.png"
                    class="card-img-top" alt="...">
                  <div class="card-body p1rem">
                    <h5 class="card-title text-center">Seminovos</h5>
                  </div>
                </a>
              </div>
              <div class="col">
                <a href="/#/aplicacoes" class="notalink card border-0">
                  <img
                    src="https://api-site-mig.sotreq.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBbmtHIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--cea4ea608fe9e2fa47125d8202dd862e15af2867/tecnologia.png"
                    class="card-img-top" alt="...">
                  <div class="card-body p1rem">
                    <h5 class="card-title text-center">Tecnologia</h5>
                  </div>
                </a>
              </div>
              <div class="col">
                <a href="/#/pecas-online" class="notalink card border-0">
                  <img
                    src="https://api-site-mig.sotreq.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBbm9HIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--39f8056da4a9a066636c1f370d575ed470b75ee7/pecasonline.png"
                    class="card-img-top" alt="...">
                  <div class="card-body p1rem">
                    <h5 class="card-title text-center">Peças on-line</h5>
                  </div>
                </a>
              </div>
              <div class="col">
                <a href="/#/carreiras" class="notalink card border-0">
                  <img
                    src="https://api-site-mig.sotreq.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBbjBHIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--56d6b157a6ee06da3cab103e1a0d3cbdcd55c627/carreira.png"
                    class="card-img-top" alt="...">
                  <div class="card-body p1rem">
                    <h5 class="card-title text-center">Carreira</h5>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <!-- fim da col esquerda -->

          <div class="col-12 col-lg-8 d-flex flex-column justify-content-between border-left border-default">
<div id="nothits" class="h-100 d-flex flex-column justify-content-between">
            <ul class="list-group list-group-flush text-left">
              <h5 class="font-weight-bold text-left mb-1 hbartitle">Isso pode ser do seu interesse:
              </h5>
              <a href="/#/nossasmaquinas/catg/6" class="list-group-item notalink">Escavadeira</a>
              <a href="/#/nossasmaquinas/catg/36" class="list-group-item notalink">Miniescavadeira</a>
              <a href="/#/aluguel" class="list-group-item notalink">Aluguel</a>
              <a href="/#/nossasmaquinas/catg/13" class="list-group-item notalink">Carregadeira</a>
              <a href="/#/lab-sos" class="list-group-item notalink">Laboratório de Fluidos</a>
            </ul>
            <h5 class="font-weight-bold text-left hbartitle">
              Precisa de ajuda?
            </h5>
            <div class="row row-cols-2 row-cols-lg-4">
              <div class="col">
                <a href="/#/faleconosco" class="notalink card py-4 py-lg-0 border-1 border-md-0 justify-content-center">
                  <svg xmlns="http://www.w3.org/2000/svg" height="2em"
                    viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                    <path
                      d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z" />
                  </svg>
                  <div class="card-body p1rem mt-4 mt-lg-0">
                    <h5 class="card-title text-center">Fale Conosco</h5>
                  </div>
                </a>
              </div>
              <div class="col">
                <a class="notalink card py-4 py-lg-0 border-1 border-md-0 justify-content-center" href="/#/filiais">
                  <svg xmlns="http://www.w3.org/2000/svg" height="2em"
                    viewBox="0 0 384 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                    <path
                      d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" />
                  </svg>
                  <div class="card-body p1rem mt-4 mt-lg-0">
                    <h5 class="card-title text-center">Filiais</h5>
                  </div>
                </a>
              </div>
              <div class="col">
                <a href="https://wa.me/551130031920" class="notalink card py-4 py-lg-0 border-1 border-md-0 justify-content-center">
                  <svg xmlns="http://www.w3.org/2000/svg" height="2em"
                    viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                    <path
                      d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z" />
                  </svg>
                  <div class="card-body p1rem mt-4 mt-lg-0">
                    <h5 class="card-title text-center">WhatsApp</h5>
                  </div>
                </a>
              </div>
              <div class="col">
                <a href="https://t.me/SotreqBot" class="notalink card py-4 py-lg-0 border-1 border-md-0  justify-content-center">
                  <svg xmlns="http://www.w3.org/2000/svg" height="2em"
                    viewBox="0 0 496 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                    <path
                      d="M248,8C111.033,8,0,119.033,0,256S111.033,504,248,504,496,392.967,496,256,384.967,8,248,8ZM362.952,176.66c-3.732,39.215-19.881,134.378-28.1,178.3-3.476,18.584-10.322,24.816-16.948,25.425-14.4,1.326-25.338-9.517-39.287-18.661-21.827-14.308-34.158-23.215-55.346-37.177-24.485-16.135-8.612-25,5.342-39.5,3.652-3.793,67.107-61.51,68.335-66.746.153-.655.3-3.1-1.154-4.384s-3.59-.849-5.135-.5q-3.283.746-104.608,69.142-14.845,10.194-26.894,9.934c-8.855-.191-25.888-5.006-38.551-9.123-15.531-5.048-27.875-7.717-26.8-16.291q.84-6.7,18.45-13.7,108.446-47.248,144.628-62.3c68.872-28.647,83.183-33.623,92.511-33.789,2.052-.034,6.639.474,9.61,2.885a10.452,10.452,0,0,1,3.53,6.716A43.765,43.765,0,0,1,362.952,176.66Z" />
                  </svg>
                  <div class="card-body p1rem">
                    <h5 class="card-title text-center mt-4 mt-lg-0">Telegram</h5>
                  </div>
                </a>
              </div>
            </div>
  </div>
            <div id="paihits">
              <h5 class="font-weight-bold text-left mb-1 hbartitle">Resultados da busca:
              </h5>
              <ul id="hits">


              </ul>
            </div>
   
          </div>
        </div>
        <div class="w-100 d-flex  justify-content-center">
          <a href="#" id="caret_close" type="button" data-toggle="collapse" data-target="#collapseExample"
            aria-expanded="false" aria-controls="collapseExample"> <img class="upcaret"
              src="	https://sotreq.com.br/img/up-arrow.b9e9e9bb.svg" />
          </a>
        </div>
      </div>
    </div>
    </div>

  <!-- fim do collapse busca -->

  <!-- inicio modal busca mobile -->
  <div id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" class="modal fade">
    <div class="modal-dialog h-100">
      <div class="modal-content w-100 ">
        <div class="modal-body">
          <div class="row">

            <div class="py-2 col-12 pb-4">
              <div id="searchboxmob"></div>
            </div>


            <div class="col-12 col-lg-8 d-flex flex-column justify-content-between border-left border-default pb-2">
<div id="nothitsmob">
              <ul class="list-group list-group-flush text-left" >
                <h5 class="font-weight-bold text-left mb-1 hbartitle">Isso pode ser do seu interesse:
                </h5>
                <a href="/#/nossasmaquinas/catg/6" class="list-group-item notalink">Escavadeira</a>
                <a href="/#/nossasmaquinas/catg/36" class="list-group-item notalink">Miniescavadeira</a>
                <a href="/#/aluguel" class="list-group-item notalink">Aluguel</a>
                <a href="/#/nossasmaquinas/catg/13" class="list-group-item notalink">Carregadeira</a>
                <a href="/#/lab-sos" class="list-group-item notalink">Laboratório de Fluidos</a>
              </ul>

                <h5 class="font-weight-bold text-left mb-4 mt-4 hbartitle">
                  Precisa de ajuda?
                </h5>
                <div class="row row-cols-2 g-1">
                  <div class="col mb-4">
                    <a href="/#/faleconosco" class="notalink card py-2 border-1 justify-content-center">
                      <svg xmlns="http://www.w3.org/2000/svg" height="2em"
                        viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                        <path
                          d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z" />
                      </svg>
                      <div class="card-body p1rem mt-2">
                        <h5 class="card-title text-center mb-1">Fale Conosco</h5>
                      </div>
                    </a>
                  </div>
                  <div class="col">
                    <a class="notalink card py-2  border-1 justify-content-center" href="/#/filiais">
                      <svg xmlns="http://www.w3.org/2000/svg" height="2em"
                        viewBox="0 0 384 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                        <path
                          d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" />
                      </svg>
                      <div class="card-body p1rem mt-2">
                        <h5 class="card-title text-center mb-1">Filiais</h5>
                      </div>
                    </a>
                  </div>
                  <div class="col">
                    <a href="https://wa.me/551130031920" class="notalink card py-2  border-1  justify-content-center">
                      <svg xmlns="http://www.w3.org/2000/svg" height="2em"
                        viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                        <path
                          d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z" />
                      </svg>
                      <div class="card-body p1rem mt-2">
                        <h5 class="card-title text-center mb-1">WhatsApp</h5>
                      </div>
                    </a>
                  </div>
                  <div class="col">
                    <a href="https://t.me/SotreqBot" class="notalink card py-2  border-1  justify-content-center">
                      <svg xmlns="http://www.w3.org/2000/svg" height="2em"
                        viewBox="0 0 496 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                        <path
                          d="M248,8C111.033,8,0,119.033,0,256S111.033,504,248,504,496,392.967,496,256,384.967,8,248,8ZM362.952,176.66c-3.732,39.215-19.881,134.378-28.1,178.3-3.476,18.584-10.322,24.816-16.948,25.425-14.4,1.326-25.338-9.517-39.287-18.661-21.827-14.308-34.158-23.215-55.346-37.177-24.485-16.135-8.612-25,5.342-39.5,3.652-3.793,67.107-61.51,68.335-66.746.153-.655.3-3.1-1.154-4.384s-3.59-.849-5.135-.5q-3.283.746-104.608,69.142-14.845,10.194-26.894,9.934c-8.855-.191-25.888-5.006-38.551-9.123-15.531-5.048-27.875-7.717-26.8-16.291q.84-6.7,18.45-13.7,108.446-47.248,144.628-62.3c68.872-28.647,83.183-33.623,92.511-33.789,2.052-.034,6.639.474,9.61,2.885a10.452,10.452,0,0,1,3.53,6.716A43.765,43.765,0,0,1,362.952,176.66Z" />
                      </svg>
                      <div class="card-body p1rem mt-2">
                        <h5 class="card-title text-center mb-1">Telegram</h5>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div id="paihitsmob">
                <h5 class="font-weight-bold text-left mb-1 hbartitle">Resultados da busca:
                </h5>
                <ul id="hitsmob">


                </ul>
              </div>

            </div>
          </div>

          <div class="d-block d-sm-block d-md-block d-lg-none">
            <div class="w-100 d-flex  justify-content-center"><button type="button" data-dismiss="modal"
                aria-label="Close" id="caret_close" class="close"><img class="upcaret" src="	https://sotreq.com.br/img/up-arrow.b9e9e9bb.svg" /></button></div>
          </div>
          <div class="d-none d-sm-none d-md-none d-lg-block"><a href="#" id="caret_close">
              <div class="w-100 d-flex  justify-content-center"><img class="upcaret" src="	https://sotreq.com.br/img/up-arrow.b9e9e9bb.svg" />
              </div>
            </a></div>
        </div>
      </div>
    </div>
</div>
  <!-- fim modal busca mobile -->
</div>
</template>
<script type="text/javascript">
import Autocomplete from '@trevoreyre/autocomplete-vue'
import Termosbuscados from '@/components/Termosbuscados.vue'

export default {
  name: 'DesktopHeaderBusca',
  mounted () {
    this.loadbuscas()
  },
  components: {
    Autocomplete,
    Termosbuscados
  },
  methods: {
    async loadbuscas () {
 // console.log('teste')
},
  }
  } 
</script>

  <style scoped>
  input #searchModule {
    color: #000000 !important;
  }

  .link-black {
    color: #000000;
  }

  button,
  input {
    margin: 0;
  }

  button,
  input {
    background-color: rgba(0, 0, 0, 0);
    color: #000000;
    font-size: inherit;
    line-height: inherit;
  }

  button,
  input {
    overflow: visible;
  }

  button {
    text-transform: none;
  }

  [type='submit'],
  button {
    -webkit-appearance: button;
  }

  [type='submit']::-moz-focus-inner,
  button::-moz-focus-inner {
    border-style: none;
    padding: 0;
  }

  [type='submit']:-moz-focusring,
  button:-moz-focusring {
    outline: 1px dotted ButtonText;
  }

  [type='search'] {
    -webkit-appearance: textfield;
    outline-offset: -2px;
  }

  [tabindex],
  a,
  button,
  input,
  label {
    -ms-touch-action: manipulation;
    touch-action: manipulation;
  }

  * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  .right {
    float: right;
  }

  .visibility-hidden {
    opacity: 0;
    position: absolute;
    left: -9999999px;
    top: -9999999px;
    visibility: hidden;
    width: 0;
    display: block;
    height: 0;
  }

  .searchBar {
    display: none;
  }

  .searchBar--overlay {
    background: rgba(51, 51, 51, 0.3);
    bottom: 0;
    position: absolute;
    left: 0;
    overflow: auto;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 100;
  }

  .searchBar.open {
    display: block;
  }

  .searchBar--wrapper {
    background: #f1f2f2;
    position: absolute;
    padding-top: 21px;
    width: 100%;
    top: 49px;
    z-index: 2001;
    left: auto;
  }

  .searchBar .arrow {
    border-bottom: 15px solid #f1f2f2;
    border-left: 15px solid rgba(0, 0, 0, 0);
    border-right: 15px solid rgba(0, 0, 0, 0);
    content: '';
    height: 0;
    margin-left: -6px;
    position: absolute;
    top: -9px;
    right: 15px;
    width: 0;
  }

  .searchBar .arrow:before {
    border-bottom: 15px solid #f1f2f2;
    border-left: 15px solid rgba(0, 0, 0, 0);
    border-right: 15px solid rgba(0, 0, 0, 0);
    content: '';
    height: 0;
    top: 0;
    left: -9px;
    margin-left: -6px;
    position: absolute;
    width: 0;
  }

  .searchModule .container {
    border-bottom: 0;
  }

  @media (min-width: 48rem) and (max-width: 63.9375rem) {
    .searchModule--wrapper {
      padding: 0 15px;
    }
  }

  @media (min-width: 64rem) {
    .searchModule--wrapper {
      padding: 0 22px;
    }
  }

  .searchModule--input {
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    border-bottom: 1px solid #939598;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    margin-bottom: 40px;
    padding-bottom: 7px;
    padding-top: 30px;
  }

  .searchModule--input,
  .searchModule--input-icon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .searchModule--input-icon {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-right: 17px;
  }

  .searchModule--input-icon .icon-search:before {
    font-size: 1.375rem;
  }

  .searchModule--input-txt {
    -webkit-box-flex: 2;
    -ms-flex-positive: 2;
    flex-grow: 2;
  }

  .searchModule--input-cta {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: none;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding-left: 20px;
    padding-top: 20px;
  }

  .searchModule--input-cta .cta {
    font-size: 1rem;
    display: table;
    white-space: nowrap;
    border: 0;
    padding: 0;
  }

  .searchModule--input-cta .cta.disabled {
    color: #ffc22b;
    cursor: default;
  }

  .searchModule--input-cta .cta.disabled .icon:before {
    color: #ffc22b;
  }

  .searchModule--search {
    font-size: calc(0.64706rem + 3.67647vw);
    line-height: calc(0.70588rem + 5.14706vw);
    letter-spacing: 0.0625rem;
    width: 100%;
    border: 0;
    padding: 0;
  }

  .searchModule--popular {
    padding-bottom: 40px;
  }

  .searchModule--popular-items {
    display: block;
  }

  .searchModule--popular-item {
    margin-right: 0;
    margin-bottom: 0;
  }

  .searchModule--popular-item:not(:last-child) {
    margin-bottom: 20px;
  }

  .searchModule--popular h4 {
    margin-bottom: 20px;
  }

  .searchModule--autocomplete {
    width: 100%;
    background: #f1f2f2;
    position: absolute;
    top: 0;
    left: 0;
    visibility: hidden;
    z-index: 100;
    padding-top: 30px;
    padding-bottom: 40px;
  }

  .searchModule input:-ms-input-placeholder {
    color: #b1b3b6;
  }

  .searchModule input:placeholder {
    color: #b1b3b6;
    opacity: 1;
  }

  .searchModule input {
    outline: none;
  }

  .searchModule input:focus {
    outline: none;
  }

  .searchModule input[type='search']::-ms-clear {
    display: none;
    width: 0;
    height: 0;
  }

  .searchModule input[type='search']::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }

  @media (min-width: 0) and (max-width: 47.9375rem) {
    .container {
      padding: 0 10px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
    }
  }

  @media (min-width: 0) and (max-width: 47.9375rem) {
    .searchBar--container {
      padding: 0;
    }
    .searchBar--close .icon-close-light {
      font-size: 0.9375rem;
    }
  }

  @media (min-width: 48rem) {
    .searchBar .arrow {
      right: 25px;
      top: -15px;
    }
    .searchBar--close {
      right: 30px;
    }
    .searchModule .container {
      border-bottom: 1px solid #d1d3d4;
    }
  }

  @media (min-width: 48rem) and (min-width: 48rem) and (max-width: 63.9375rem) {
    .searchModule--wrapper {
      padding: 0 15px;
    }
  }

  @media (min-width: 48rem) and (min-width: 64rem) {
    .searchModule--wrapper {
      padding: 0 22px;
    }
  }

  @media (min-width: 48rem) {
    .searchModule--input {
      padding-bottom: 10px;
      margin-bottom: 65px;
      padding-top: 70px;
    }
    .searchModule--input-icon {
      padding-right: 23px;
      padding-top: 7px;
    }
    .searchModule--input-cta {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      padding-top: 17px;
    }
  }

  @media (min-width: 48rem) {
    .searchModule--popular {
      padding-bottom: 70px;
    }
  }

  @media (min-width: 48rem) and (max-width: 63.9375rem) {
    .searchBar--wrapper {
      top: 69px;
    }
    .searchBar--close {
      top: 29px;
    }
  }

  @media (min-width: 64rem) {
    .searchBar--wrapper {
      top: 89px;
    }
    .searchBar .arrow {
      right: 35px;
    }
    .searchBar--close {
      top: 37px;
      right: 40px;
    }
    .searchModule--input-icon {
      padding-right: 27px;
      padding-top: 8px;
    }
    .searchModule--popular-items {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
    }
    .searchModule--popular-item:not(:last-child) {
      margin-right: 40px;
    }
  }

  @media screen and (min-width: 64rem) {
    .searchModule--search {
      line-height: 4rem;
      font-size: 3rem;
    }
    .searchModule--popular-item a {
      line-height: 2rem;
      font-size: 1.25rem;
    }
  }

  @media screen and (max-width: 30rem) {
    .searchModule--search {
      line-height: 2.25rem;
      font-size: 1.75rem;
    }
    .searchModule--popular-item a {
      line-height: 1.75rem;
      font-size: 1rem;
    }
  }

  @media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
    .searchModule--search {
      line-height: 0;
    }
  }

  body {
    background-image: url(https://picsum.photos/id/967/1280/1080);
    background-size: cover;
    background-repeat: no-repeat;
  }

  .cool-link {
    display: inline-block;
    color: #ffc22b;
    text-decoration: none;
  }

  .cool-link::after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: #ffc22b;
    transition: width 0.3s;
  }

  li.show > .cool-link::after {
    width: 100%;
  }

  .cool-link:hover::after {
    width: 100%;
    /*transition: width .3s;*/
  }

  .navbar-sotreq {
    height: 80px;
    z-index: 99;
  }

  .header-logo {
    width: 166px;
  }

  .dropdown-toggle.active-dropdown::after {
    transform: rotate(-90deg);
  }

  .dropdown-menu {
    margin: 0px;
  }

  .dropdown-menu.show {
    display: flex;
    /*top: unset !important;*/
    left: 0 !important;
    width: 100%;
    border-left-width: 0px !important;
    border-right-width: 0px !important;
    border-radius: 0px !important;
  }

  a.dropdown-item.dropdown-toggle {
    /*padding: .5rem 1rem;*/
  }

  .img-menu-box {
    padding-right: 2em;
    border-right: 1px solid #d6d6d6;
  }

  a.dropdown-item.dropdown-toggle[aria-expanded='true'] {
    /*padding: .5rem 1rem;*/
    color: #ffc22b !important;
    /*border-bottom: 2px solid #ffc22b;*/
  }

  #navbarNavDropdown > ul > li.nav-item.dropdown.show > ul > div > li.show > a {
    /*padding: .5rem 1rem;*/
    color: #ffc22b !important;
    /*border-bottom: 2px solid #ffc22b;*/
  }

  .dropdown-item {
    color: #ffffff;
    padding: 0.5rem 1rem;
  }

  .dropdown-item:hover {
    color: #ffc22b !important;
    /*border-bottom: 2px solid #ffc22b;*/
  }

  .dropdown-item-in {
    color: #000000;
    padding: 0.5rem 1rem;
  }

  .dropdown-item-in:hover {
    color: #ffc22b !important;
    /*border-bottom: 2px solid #ffc22b;*/
  }

  .dropdown,
  di .dropleft,
  .dropright,
  .dropup {
    position: revert !important;
  }

  .navbar-expand-md .navbar-collapse {
    justify-content: center;
  }

  .navbar.navbar-expand-md.navbar-dark.navbar-sotreq {
    background: rgb(0, 0, 0);
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 0.5202970095593544) 50%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  .navbar.navbar-expand-md.navbar-dark.navbar-sotreq.over {
    background: #ffffff !important;
    color: #000 !important;
  }

  .navbar-sotreq.over > .container > #navbarNavDropdown > ul > li > a {
    color: #000000;
  }

  .navbar-sotreq.over
    > .container
    > #navbarNavDropdown
    > ul
    > li.nav-item.dropdown.show
    > ul
    > div
    > li.show
    > ul
    > div
    > div
    > div
    > div
    > div
    > ul
    > li
    > a {
    color: #000000;
  }

  .navbar-sotreq.over
    > .container
    > #navbarNavDropdown
    > ul
    > li.nav-item.dropdown.show
    > ul
    > div
    > li.show
    > ul
    > div
    > div
    > div
    > div
    > div.pl-4.mx-0
    > ul
    > li
    > a {
    color: #000000;
  }

  .navbar-sotreq.over > .container > #navbarNavDropdown > ul > li.nav-item.dropdown.show > ul > div > li > a {
    color: #000000;
  }

  .navbar-sotreq.front-link {
    color: #ffffff;
  }

  .internal-link {
    color: #000000;
  }

  .navbar.navbar-expand-md.navbar-dark.navbar-sotreq:hover {
    background: #ffffff !important;
    color: #000000;
  }

  .navbar-sotreq:hover > .container > #navbarNavDropdown > ul > li > a {
    color: #000000;
  }

  .navbar-sotreq:hover > .container > #navbarNavDropdown > ul > li > a.nav-link:hover {
    color: #ffc22b !important;
    /*border-bottom: 2px solid #ffc22b;*/
  }

  .dropdown-item:focus,
  .dropdown-item:hover {
    color: #ffc22b !important;
    background-color: unset;
  }

  .dropdown-item:focus,
  .dropdown-item:hover {
    color: #ffc22b;
    background-color: unset;
  }

  #navbarNavDropdown > ul {
    width: 100%;
    display: flex;
    justify-content: space-around;
  }

  li.nav-item.dropdown.show > ul.dropdown-menu {
    border: 1px solid #d6d6d6;
    border-radius: 0px;
    padding-bottom: 1em;
    padding-top: 1em;
    /*margin: 1rem 0 0;*/
  }

  li.show > ul.dropdown-menu.show {
    border-top: 1px solid #d6d6d6;
    border-bottom: none;
    /*margin: 1rem 0 0;*/
  }

  a.internal-link > h6 {
    margin-bottom: 1.5em;
  }

  h6.internal-link {
    margin-bottom: 1.5em;
  }

  .upcaret {
    width: 3em;
  }

  .navbar-dark .navbar-nav .nav-link {
    color: #ffffff;
  }

  a.internal-link.text-muted > h6 {
    padding-bottom: 1rem;
    margin-bottom: 0rem;
  }

  .dropdown-toggle::after {
    display: block;
    margin-left: unset;
    vertical-align: unset;
    content: '';
    border-top: unset;
    border-right: unset;
    border-bottom: unset;
    border-left: unset;
  }

  .lupa-busca {
    color: #ffc22b !important;
  }

  .termos-buscados {
    color: #ffc22b !important;
    text-transform: uppercase;
  }
  .w-sidebar {
    width: 200px;
    max-width: 200px;
    margin-top: 3em;
  }

  .vh-100 {
    min-height: 100vh;
  }

  .row.collapse {
    margin-left: -200px;
    left: 0;
    transition: margin-left 0.15s linear;
  }

  .row.collapse.show {
    margin-left: 0 !important;
  }

  .row.collapsing {
    margin-left: -200px;
    left: -0.05%;
    transition: all 0.15s linear;
  }

  .menu-spacer {
    position: absolute;
  }

  @media (max-width: 1000px) {
    .copy-text {
      font-size: 14px;
    }
    .blue-button {
      width: 100%;
      justify-content: center;
    }
    .download-button {
      max-width: unset;
    }
    .hero-logo {
      width: 65%;
    }
    .map-box {
      min-height: 10em;
      min-width: 22em;
    }
    .row.collapse,
    .row.collapsing {
      margin-left: 0 !important;
      left: 0 !important;
      overflow: visible;
    }

    .row > .sidebar.collapse {
      display: flex !important;
      margin-left: -100% !important;
      transition: all 0.3s linear;
      position: fixed;
      z-index: 1050;
      max-width: 0;
      min-width: 0;
      flex-basis: auto;
    }

    .row > .sidebar.collapse.show {
      margin-left: 0 !important;
      width: 100%;
      max-width: 100%;
      min-width: initial;
    }

    .row > .sidebar.collapsing {
      display: flex !important;
      margin-left: -10% !important;
      transition: all 0.3s linear !important;
      position: fixed;
      z-index: 1050;
      min-width: initial;
    }
    .hamburger {
      position: fixed;
      display: flex;
      flex-direction: row-reverse;
      width: 100%;
      justify-content: space-between;
      background-color: #000;
    }
    .mob-brand {
      height: 56px;
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
    }
    .main-menu {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
    }
    .fa-bars {
      align-self: center;
      font-size: 2.333em !important;
      color: #fff !important;
    }
    .header-logo {
      width: 150px;
    }
    .mob-search {
      color: #fff;
      margin-right: 1em;
    }
    .w-sidebar {
      margin-top: 2em;
    }
    .lblue-bg {
      background-color: #ececec;
    }

    .mob-card {
      background-color: #ececec;
      color: #fff;
    }
    .mob-card-header {
      background-color: #ececec;
    }
    .btn-outline-warning {
      color: #ffc107 !important;
    }
    .btn-outline-warning:hover {
      color: #fffblz per !important;
    }
    .mob-internal-link {
      color: #000;
    }
    .btn.btn-link.btn-block.text-left.collapsed {
      color: #000000;
      font-size: 24px;
    }
    .btn.not(:disabled):not(.disabled) {
      color: #4a4a4a;
      background-color: #000c;
      font-size: 24px;
    }
    .btn.btn-link.btn-block.text-left.collapsed:focus {
      box-shadow: none;
      text-decoration: none;
    }
    .btn.btn-link.btn-block.text-left.collapsed:hover {
      box-shadow: none;
      text-decoration: none;
    }
    btn.focus,
    .btn:focus {
      outline: 0;
      box-shadow: none;
      text-decoration: none;
    }
    .btn.btn-link.btn-block.text-left {
      color: #4a4a4a;
      font-size: 24px;
    }

    .btn.btn-link.btn-block.text-left:hover {
      color: #4a4a4a;
      text-decoration: none;
      font-size: 24px;
    }
    .mob-menu-card-body {
      text-align: left;
    }
    .mob-menu-list {
      list-style: none;
      padding-inline-start: 15px !important;
    }
    .row.vh-100.no-gutters.d-flex.h-100.position-absolute.collapse.show {
      top: 0;
    }
  }

  @media (max-width: 538px) {
    .internal-link h6 {
      color: #ffffff;
    }
    li.nav-item {
      margin-bottom: 1em;
    }
  }
  .mainNav-collapsible .mobileNavOpen .mainNav--itemTitle {
    opacity: 1;
    -webkit-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transition: opacity 0.5s, -webkit-transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    transition: opacity 0.5s, -webkit-transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    -o-transition: opacity 0.5s, transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    transition: opacity 0.5s, transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    transition: opacity 0.5s, transform 0.5s cubic-bezier(0.4, 0, 0.2, 1),
      -webkit-transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    z-index: 1;
    display: block;
    position: relative;
  }
  .mainNav-collapsible .mainNav--parent .icon-dropdown-arrow:before {
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
    -webkit-transition: -webkit-transform 0.3s linear;
    transition: -webkit-transform 0.3s linear;
    -o-transition: transform 0.3s linear;
    transition: transform 0.3s linear;
    transition: transform 0.3s linear, -webkit-transform 0.3s linear;
    position: relative;
    display: inline-block;
    font-size: 0.45rem;
    margin-left: 5px;
  }
  .mainNav-collapsible .mainNav--parent .icon-dropdown-arrow:before {
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
    -webkit-transition: -webkit-transform 0.3s linear;
    transition: -webkit-transform 0.3s linear;
    -o-transition: transform 0.3s linear;
    transition: transform 0.3s linear;
    transition: transform 0.3s linear, -webkit-transform 0.3s linear;
    position: relative;
    display: inline-block;
    font-size: 0.45rem;
    margin-left: 5px;
  }
  i.fa.fa-angle-right {
    margin-left: 1em;
  }
  #collapsemObs1 {
    padding-left: 0.7em;
  }

  .btn.btn-link.btn-block.text-left.cool-link:not(.collapsed) > i.fa.fa-angle-right {
    transform: rotate(90deg);
  }
  .lblue-bg {
    overflow: scroll;
  }

  </style>
