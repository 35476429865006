<template>
  <div class="home">
    <Header />
    <HeroMercados :data="bannermercado" />
    <div class="container">
     <div class="row">
          <div class="col mt-5 text-left">
            <h2 class="ml-0 pl-0  font-weight-bold">
              {{ this.destaqueTitulo }}
            </h2>
    <div v-if="this.conteudo.length > 1">
          <div class="resetforcontent mt-3">
          <div  v-html="this.conteudo"></div>
          </div>
          </div>
          </div>
        </div>

      <div class="row">
         <div class="text-left card-body">
            <img src="https://api-site-mig.sotreq.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBczhHIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--bfaa984043c950d768f433ca59f0d28017879c5a/sustentage-1.png" class="img-fluid fill-av" alt="Responsive image" />
          </div>
       </div>
    </div>

 <Footer :buttonless="true" />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import CollapseSustentabilidade from './CollapseSustentabilidade.vue'
import HeroMercados from '@/components/Mercados/HeroMercados.vue'
import Footer from '@/components/Footer.vue'
import ApiContent from '@/services/api.js'

export default {
  name: 'Politica',
  mounted() {
    this.loadContent()
  },
  components: {
    Header,
    HeroMercados,
    Footer,
    CollapseSustentabilidade
  },
  methods: {
    async loadContent() {
      const resp = await ApiContent.get('paginas/191.json')
      console.log(resp.data)
      this.heroTopTitulo = resp.data.heroTopTitulo
      this.heroTopTexto = resp.data.heroTopTexto
      this.heroTopLink = resp.data.heroTopLink
      this.destaqueTitulo = resp.data.destaqueTitulo
      this.destaqueTexto = resp.data.destaqueTexto
      this.destaqueChamada = resp.data.destaqueChamada
      this.textoBaseTitulo = resp.data.textoBaseTitulo
      this.textoBaseTexto = resp.data.textoBaseTexto
      this.heroBottomTitulo = resp.data.heroBottomTitulo
      this.heroBottomTexto = resp.data.heroBottomTexto
      this.heroBottomLink = resp.data.heroBottomLink
      this.bottomherobuttontext = resp.data.bottomherobuttontext
      this.heroBottomLink = resp.data.heroBottomLink
      this.destaques = resp.data.destaques
      this.url = resp.data.url
      this.topHeroImage = resp.data.topHeroImage
      this.logo = resp.data.logo
      this.bottomHeroImage = resp.data.bottomHeroImage
      this.cards = resp.data.cards
      this.conteudo = resp.data.content
      this.bannermercado = {
        titulo: resp.data.heroTopTitulo,
        image: resp.data.topHeroImage,
        subtitulo: resp.data.subtitulo,
        texto: resp.data.texto,
        link: resp.data.link,
        buttontext: resp.data.buttontext,
        herocontent: resp.data.herocontent
      }
    }
  },
  data() {
    return {
      heroTopTitulo: [],
      heroTopTexto: [],
      heroTopLink: [],
      destaqueTitulo: [],
      destaqueTexto: [],
      destaqueChamada: [],
      textoBaseTitulo: [],
      textoBaseTexto: [],
      heroBottomTitulo: [],
      heroBottomTexto: [],
      heroBottomLink: [],
      created_at: [],
      updated_at: [],
      url: [],
      topHeroImage: [],
      logo: [],
      bottomHeroImage: [],
      cards: [],
      conteudo: []
    }
  }
}
</script>
<style scoped>
.quadrado {
  border: 5px solid #ffcb11;
}

.equipamento_nvo {
  background: rgb(2, 0, 36);
  background: radial-gradient(circle,
      rgba(2, 0, 36, 1) 0%,
      rgba(214, 155, 31, 1) 50%);
}

a {
  text-decoration: none;
}

button {
  border: 2px solid white;
  background-color: black;
  color: white;
}

button:hover {
  border: 2px solid black;
  background-color: white;
  color: black;
}
.fill-av {
      min-width: -webkit-fill-available;
      min-width: -moz-available;
}
</style>
