<template>
  <div class="home">
    <Header />
    <HeroMercados :data="bannermercado" />

    <div class="container">
      <div class="row">
        <div class="col mt-0 mt-lg-4 text-left">
          <h1 class="ml-0 pl-0 mt-2 main-heading">
            {{ this.destaqueTitulo }}
          </h1>
          <p style="white-space: pre-line" class="mt-3 mb-0">
            {{ this.destaqueTexto }}
          </p>
        </div>
      </div>

      <DestaqueMineracao
        v-for="(carregadeira, index) in carregadeiras"
        :key="index"
        :image="carregadeira.image"
        :titulo="carregadeira.titulo"
        :text="carregadeira.text"
        :inverted="true"
      />

        <h4 class="ml-0 pl-0 mt-1 mt-md-2 text-left fw-700  main-heading" style="font-size:24px">
          {{ this.destaqueChamada }}
        </h4>

        <div v-if="cards.length > 0" class="d-flex flex-wrap row">
          <MineracaoSquarecard
            v-for="(item, index) in cards"
            :key="index"
            :link="item.link"
            :image="item.image"
            :titulo="item.titulo"
          />
        </div>
      <div class="mx-0 px-0 mt-0">
        <DestaqueMineracao
          v-for="(caminhao, index) in caminhoes"
          :key="index"
          :image="caminhao.image"
          :titulo="caminhao.titulo"
          :text="caminhao.text"
          :inverted="false"
        />
      </div>
      <div class="mx-0 px-0 mt-0">
        <h4 class="ml-0 pl-0 mt-2 text-left fw-700">
          {{ this.textoBaseTitulo }}
        </h4>

        <div v-if="destaques.length > 0" class="d-flex flex-wrap row">
          <MineracaoSquarecard
            v-for="(item, index) in destaques"
            :key="index"
            :link="item.link"
            :image="item.image"
            :titulo="item.titulo"
          />
        </div>
      </div>
      <br><br>
      <widecard />
    </div>

    <Footer :buttonless="true" />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header.vue";
import CardSobrenos from "@/components/CardSobrenos.vue";
import DestaqueMineracao from "@/components/DestaqueMineracao.vue";
import HeroMercados from "@/components/HeroMineracaoPaginas.vue";
import Footer from "@/components/Footer.vue";
import ApiContent from "@/services/api.js";
import Novidade from "@/components/Novidade.vue";
import MineracaoSquarecard from "@/components/MineracaoSquarecard.vue";
import Widecard from '../../components/Mercados/widecard.vue';

export default {
  name: "TecnologiaSolucoesSotreqlink",
  mounted() {
    this.loadContent();
  },
  components: {
    Header,
    CardSobrenos,
    DestaqueMineracao,
    HeroMercados,
    Footer,
    Novidade,
    MineracaoSquarecard,
    Widecard
  },
  methods: {
    async loadContent() {
      const resp = await ApiContent.get("paginas/198.json");
      console.log(resp.data);
      this.heroTopTitulo = resp.data.heroTopTitulo;
      this.heroTopTexto = resp.data.heroTopTextos;
      this.heroTopLink = resp.data.heroTopLink;
      this.destaques = resp.data.destaques;
      this.destaqueTitulo = resp.data.destaqueTitulo;
      this.destaqueTexto = resp.data.destaqueTexto;
      this.destaqueChamada = resp.data.destaqueChamada;
      this.textoBaseTitulo = resp.data.textoBaseTitulo;
      this.textoBaseTexto = resp.data.textoBaseTexto;
      this.heroBottomTitulo = resp.data.heroBottomTitulo;
      this.heroBottomTexto = resp.data.heroBottomTexto;
      this.heroBottomLink = resp.data.heroBottomLink;
      this.bottomherobuttontext = resp.data.bottomherobuttontext;
      this.heroBottomLink = resp.data.heroBottomLink;
      this.url = resp.data.url;
      this.topHeroImage = resp.data.topHeroImage;
      this.logo = resp.data.logo;
      this.bottomHeroImage = resp.data.bottomHeroImage;
      this.cards = resp.data.cards.map((s) => {
        s.image = s.imagem;
        s;
        return s;
      });
      this.bannermercado = {
        titulo: resp.data.heroTopTitulo,
        image: resp.data.topHeroImage,
        subtitulo: resp.data.subtitulo,
        texto: resp.data.texto,
        link: resp.data.link,
        buttontext: resp.data.buttontext,
        herocontent: resp.data.herocontent,
      };
      (this.carregadeiras = [
        {
          image:
            "https://api-site-mig.sotreq.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBcUlHIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--7d348f008fc5f4d51b61da771f88dd99ffb5da06/image-1.png",
          titulo: "Carregadeiras subterrâneas Cat",
          text:
            "As carregadeiras subterrâneas Cat® têm força e durabilidade para trabalhar em condições difíceis, mas são seus controles precisos e a operação eficiente que as diferenciam.\n\Nossas carregadeiras subterrâneas são construídas para enfrentar os desafios difíceis e únicos da mineração subterrânea, fazendo de tudo, desde aumentar a produtividade e a eficiência até melhorar a segurança. Com uma ampla variedade de classes de tamanho, configurações e ferramentas de penetração no solo, oferecemos uma carregadeira ideal para sua aplicação específica, ajudando você a transformar esses desafios em oportunidades.",
        },
      ]),
        (this.caminhoes = [
          {
            image:
              "https://api-site-mig.sotreq.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBcU1HIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--f88a7682d88912f45f772ddf54092ec5242917c8/image-3.png",
            titulo: "Caminhões subterrâneos",
            text:
              "Com uma ampla variedade de opções de tamanho e carrocerias, temos uma linha de produtos flexível que pode ser adaptada para atender às necessidades específicas da sua aplicação. E, por meio da rede global de revendedores Cat, você tem acesso a suporte local apoiado por uma equipe mundial de especialistas e inovadores, todos focados em manter suas máquinas funcionando de forma produtiva e eficiente.",
          },
        ]);
    },
  },
  data() {
    return {
      heroTopTitulo: [],
      heroTopTexto: [],
      heroTopLink: [],
      destaqueTitulo: [],
      destaqueTexto: [],
      destaques: [],
      destaqueChamada: [],
      textoBaseTitulo: [],
      textoBaseTexto: [],
      heroBottomTitulo: [],
      heroBottomTexto: [],
      heroBottomLink: [],
      created_at: [],
      updated_at: [],
      url: [],
      topHeroImage: [],
      logo: [],
      bottomHeroImage: [],
      cards: [],
      carregadeiras: [],
      caminhoes: [],
    };
  },
};
</script>
<style scoped>
.quadrado {
  border: 5px solid #ffcb11;
}

.paragraph {
  color: #000;
  font-family: Univers;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.equipamento_nvo {
  background: rgb(2, 0, 36);
  background: radial-gradient(circle, rgba(2, 0, 36, 1) 0%, rgba(214, 155, 31, 1) 50%);
}

a {
  text-decoration: none;
}

button {
  border: 2px solid white;
  background-color: black;
  color: white;
}

button:hover {
  border: 2px solid black;
  background-color: white;
  color: black;
}
</style>
