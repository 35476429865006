<template>
  <div class="home">
    <Header />
    <HeroMercados :data="bannermercado" />
    <div class="container">
      <div class="row">
        <div class="col mt-4 text-left">
          <h2 class="ml-0 pl-0 mt-2 font-weight-bold">
            {{ this.destaqueTitulo }}
          </h2>
          <p style="white-space: pre-line" class="mt-3">
            As Ferramentas de Penetração no Solo – FPS, são os seus maiores aliados quando a questão é proteção e
            produtividade. Devido a natureza de seu trabalho, desgastam-se constantemente, tornando imprescindível o
            gerenciamento e controle dos seus custos. Na hora de substituir as FPS dos seus equipamentos, opte por peças
            originais. Você garante a excelente produtividade das suas máquinas. Além de economizar nos custos de operação
            e manutenção. Com a vantagem das FPS Cat também reduzirem o desgaste das peças estruturais ou de acessórios
            mais caros.
          </p>
          <h2 class="ml-0 pl-0 mt-2 font-weight-bold text-yellow">
            Veja abaixo as opções e escolha a mais adequada para sua operação.
          </h2>
        </div>
      </div>

      <div class="desatque my-4">
        <div class="row px-0 mx-0">
          <div class="col px-0 mx-0">
            <div class="card mb-3">
              <div class="row px-0 mx-0 d-flex ">
                <div class="col-md-5 mx-0 px-0 d-flex align-items-center">
                  <img
                    src="https://api-site-mig.sotreq.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBaEVHIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--cfd33d414718d30b36a50fad636269208d9a3034/advansys.png "
                    class="img-fluid" />
                </div>
                <div class="
                          col-md-7
                          d-flex
                          flex-column
                          justify-content-center
                          py-1
                        ">
                  <div class="text-left card-body">
                    <div class="d-flex justify-content-between flex-column">
                      <div>
                        <h2 class="ml-0 pl-0  font-weight-bold">Advansys </h2>
                       <ul class="pl-2">


  <li>40% a mais de durabilidade nos adaptadores. </li>
  <li>20% a mais de durabilidade se comparada à Série K. </li>
  <li>75% mais rápida nas trocas de pontas em comparação com outras séries. </li>
  <li>Maior segurança para seus operadores e menor tempo de máquina parada. </li>
  <li>Ampla variedade de tamanhos e formas. </li>
                       </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="desatque my-4">
        <div class="row px-0 mx-0">
          <div class="col px-0 mx-0">
            <div class="card mb-3">
              <div class="row px-0 mx-0 d-flex flex-md-row-reverse">
                <div class="col-md-5 mx-0 px-0 d-flex align-items-center">
                  <img
                    src="https://api-site-mig.sotreq.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBaElHIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--93b58701ae26cd670d22e2e395b8062587c7ee68/Grupo%20de%20m%C3%A1scara%202.png"
                    class="img-fluid" />
                </div>
                <div class="
                          col-md-7
                          d-flex
                          flex-column
                          justify-content-center
                          py-1
                        ">
                  <div class="text-left card-body">
                    <div class="d-flex justify-content-between flex-column">
                       <div>
                          <h2 class="ml-0 pl-0  font-weight-bold">Série J</h2>
                         <ul class="pl-2">

   <li>Possibilidade para diversas aplicações de baixo a elevada abrasão e impacto.</li>
   <li>Disponível em diversos tamanhos e formatos para as mais variadas necessidades.</li>
                         </ul>
                        </div>
                       <div>
                            <h2 class="ml-0 pl-0  font-weight-bold"> Série J GD (General Duty)</h2>
                           <ul class="pl-2">


   <li>6 tamanhos de pontas disponíveis em 2 perfis diferentes.</li>
   <li>Linha de adaptadores J GD exclusivos com compatibilidade com adaptadores da Série J.</li>
   <li>Recomendadas para condições menos severas de baixo a moderado impacto e abrasividade.</li>
                           </ul>
                          </div>
                        </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

   <div class="col-12 my-4">
            <h4 class="font-weight-bold text-center">
              Deseja tirar uma dúvida ou fazer uma cotação?

            </h4>

            <div class="d-flex justify-content-center">
              <a href="#button" type="button" class="btn btn-yellow botao">
                ENTRAR EM CONTATO
              </a>
            </div>
          </div>



      <div data-v-0298ec4e="" class="card-deck row row-cols-1 row-cols-md-2">
        <div data-v-0298ec4e="" class="mb-4">
          <div data-v-0298ec4e="" class="h-100 card border"><img data-v-0298ec4e=""
              src="https://api-site-mig.sotreq.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBaE1HIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--8251d51a13b249873ee0c564307003e72603c609/pontasK.png"
              alt="Sotreq revendedora Caterpillar" class="card-img-top img-fluid">
            <div data-v-0298ec4e="" class="card-body text-left">
              <h2 class="ml-0 pl-0  font-weight-bold"> Pontas K </h2>
                             <ul class="pl-2">


  <li>Projetado para ampla gama de aplicações de baixa a elevada abrasão e impacto. </li>
  <li>Disponível em diversos tamanhos e formatos para mais variadas aplicações.</li>
                             </ul>
            </div>
     </div>
        </div>

        <div data-v-0298ec4e="" class="mb-4">
          <div data-v-0298ec4e="" class="h-100 card border"><img data-v-0298ec4e=""
              src="https://api-site-mig.sotreq.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBaFFHIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--02c75cca0a5be967f3c6fbc47274eb8c2025bfb7/416.png"
              alt="Sotreq revendedora Caterpillar" class="card-img-top img-fluid">
            <div data-v-0298ec4e="" class="card-body text-left">
               <h2 class="ml-0 pl-0  font-weight-bold"> Ponta DRT para Retro  </h2>
                               <ul class="pl-2">


    <li>Pontas mais robusta feita para elevar a produtividade da sua retroescavadeira com uma ótima
  relação de custo/benefício. Aplicável para as mais diversas operações.</li>
 
                               </ul>
            </div>

          </div>
        </div>
     
      </div>

   <div class="col-12 my-4">
            <h4 class="font-weight-bold text-center">
              Deseja tirar uma dúvida ou fazer uma cotação?

            </h4>

            <div class="d-flex justify-content-center">
              <a href="#button" type="button" class="btn btn-yellow botao">
                ENTRAR EM CONTATO
              </a>
            </div>
          </div>



      <div class="desatque my-4">
        <div class="row px-0 mx-0">
          <div class="col px-0 mx-0">
            <div class="card mb-3">
              <div class="row px-0 mx-0 d-flex ">
                <div class="col-md-5 mx-0 px-0 d-flex align-items-center">
                  <img
                    src="https://api-site-mig.sotreq.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBaFVHIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--ff0329447df6137dcc3e0b52072c0237c64841b0/motoniveladora.png"
                    class="img-fluid" />
                </div>
                <div class="
                            col-md-7
                            d-flex
                            flex-column
                            justify-content-center
                            py-1
                          ">
                  <div class="text-left card-body">
                    <div class="d-flex justify-content-between flex-column">
                        <div>
                          <h2 class="ml-0 pl-0  font-weight-bold">Lâminas de motoniveladora  </h2>
                         <ul class="pl-2">


  <li>Possibilidades para operações que buscam melhor custo benefício e maior produtividade para a sua máquina Caterpillar. </li>
  <li>Alta gama de possibilidades para os mais diferentes tipos de aplicações. </li>
  <li>Opções de baixo a alto impacto e abrasão.</li>
                         </ul>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="desatque my-4">
        <div class="row px-0 mx-0">
          <div class="col px-0 mx-0">
            <div class="card mb-3">
              <div class="row px-0 mx-0 d-flex flex-md-row-reverse">
                <div class="col-md-5 mx-0 px-0 d-flex align-items-center">
                  <img
                    src="https://api-site-mig.sotreq.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBaFlHIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--247ab66c2fbd6ba54d0141ae561315787a14035e/ca%C3%A7amba.png"
                    class="img-fluid" />
                </div>
                <div class="
                            col-md-7
                            d-flex
                            flex-column
                            justify-content-center
                            py-1
                          ">
                  <div class="text-left card-body">
                    <div class="d-flex justify-content-between flex-column">
                         <div>
                          <h2 class="ml-0 pl-0  font-weight-bold">Segmentos e Protetores de caçamba </h2>
                         <ul class="pl-2">


  <li>Com foco no equilíbrio entre produtividade, perfuração e proteção, nossos segmentos e protetores são referencias para quem busca custo/beneficio e produtividade. </li>
  <li>Alta gama de opções para as mais diversas aplicações.</li>
                         </ul>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>



        <div class="row my-5">
          <div class="col text-left">
            <h2 class="font-weight-bold">Informações sobre garantia</h2>
            <div class="bar mb-5"></div>
            <p class="preline">Geralmente, a garantia para peças compradas em <a target="_blank" href=""> Loja on-line de
                Peças </a>2 é de 12 meses a partir da data da fatura, conforme definido na Garantia Limitada da Caterpillar.
              A Garantia das Peças abrange defeitos no material e de fabricação durante todo o período de Garantia uma vez
              instalada. Para obter todos os detalhes sobre a Garantia, entre em contato com a Sotreq.</p>
          </div>
        </div>
            </div>
      <br>
    <Compra bgcolor="#f5f5f5" />
   <h2 class="text-center font-weight-bold my-5">
        FAÇA A ESCOLHA CERTA E ENTRE <br /> EM CONTATO COM A SOTREQ
      </h2>
      <h5 class="text-center footer-closer" id="button">
        Preencha os formulário abaixo e entraremos em contato com você.
      </h5>
      <Footer bgcolor="#f5f5f5" :buttonless="true" :hasform="true" gtmterm="pagina_penetracao_solo" />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import HeroMercados from '@/components/Mercados/HeroMercados.vue'
import Footer from '@/components/Footer.vue'
import ApiContent from '@/services/api.js'
import Compra from '@/components/Compra.vue'

export default {
  name: 'Home',
  mounted() {
    this.loadContent()
  },
  components: {
    Header,
    HeroMercados,
    Footer,
    Compra
  },
  methods: {
    async loadContent() {
      const resp = await ApiContent.get('paginas/55.json')
      console.log(resp.data)
      this.heroTopTitulo = resp.data.heroTopTitulo
      this.heroTopTexto = resp.data.heroTopTexto
      this.heroTopLink = resp.data.heroTopLink
      this.destaqueTitulo = resp.data.destaqueTitulo
      this.destaqueTexto = resp.data.destaqueTexto
      this.destaqueChamada = resp.data.destaqueChamada
      this.textoBaseTitulo = resp.data.textoBaseTitulo
      this.textoBaseTexto = resp.data.textoBaseTexto
      this.heroBottomTitulo = resp.data.heroBottomTitulo
      this.heroBottomTexto = resp.data.heroBottomTexto
      this.heroBottomLink = resp.data.heroBottomLink
      this.bottomherobuttontext = resp.data.bottomherobuttontext
      this.heroBottomLink = resp.data.heroBottomLink
      this.url = resp.data.url
      this.topHeroImage = resp.data.topHeroImage
      this.logo = resp.data.logo
      this.bottomHeroImage = resp.data.bottomHeroImage
      this.cards = resp.data.cards.map((s) => {
        s.image = s.imagem
        return s
      })
      this.bannermercado = {
        titulo: resp.data.heroTopTitulo,
        image: resp.data.topHeroImage,
        subtitulo: resp.data.subtitulo,
        texto: resp.data.texto,
        link: resp.data.link,
        buttontext: resp.data.buttontext,
        herocontent: resp.data.herocontent
      }
    }
  },
  data() {
    return {
      heroTopTitulo: [],
      heroTopTexto: [],
      heroTopLink: [],
      destaqueTitulo: [],
      destaqueTexto: [],
      destaqueChamada: [],
      textoBaseTitulo: [],
      textoBaseTexto: [],
      heroBottomTitulo: [],
      heroBottomTexto: [],
      heroBottomLink: [],
      created_at: [],
      updated_at: [],
      url: [],
      topHeroImage: [],
      logo: [],
      bottomHeroImage: [],
      cards: []
    }
  }
}
</script>
<style scoped>.quadrado {
  border: 5px solid #ffcb11;
}

.equipamento_nvo {
  background: rgb(2, 0, 36);
  background: radial-gradient(circle,
      rgba(2, 0, 36, 1) 0%,
      rgba(214, 155, 31, 1) 50%);
}

a {
  text-decoration: none;
}

button {
  border: 2px solid white;
  background-color: black;
  color: white;
}

button:hover {
  border: 2px solid black;
  background-color: white;
  color: black;
}

a.btn.btn-yellow {
  background: #FFCD00 0% 0% no-repeat padding-box;
  border-radius: 10px;
  text-align: center;
  letter-spacing: 0px;
  color: #000000;
  text-transform: uppercase;
  opacity: 1;
  padding: 11px 23px;
}

a.btn.btn-yellow:hover {
  background: #252525 0% 0% no-repeat padding-box;
  color: #fff !important;
}
.card {
  border: none !important
  }
  li {
    margin-bottom: 16px;
}

</style>
