<template>
    <div class="home">
        <Header />
        <HeroMercados :data="bannermercado" />
        <div class="container">
            <LineTextBlockExtendedText :data="this.destaques.slice(0, 1)" />
            <LineTextBlock :data="this.destaques.slice(1, 2)" />
            <!-- primeiro cards horizontais -->
    
            <div class="card-deck">
                <!-- primeiro grid cards -->
                <div class="card flex-row h-100 mt-4 col-md-6 border row py-4 ">
                    <div class="col-12 col-md-4">
                        <img class="img-fluid" src="https://api-site-mig.sotreq.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBaGNFIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--1d13171ee1ee9e6fcc022a17f6afe287c07d7d9e/energia-missao-critica.png" style="height: 127px"
                        />
                    </div>
                    <div class="col-12 col-md-8">
                        <h5 class="mt-4 text-center">Misssão Crítica</h5>
    
                        <p style="" class=" text-center grey-text">
                            Hospitais, Data Centers, Aeroportos, Telecom, Bancos, Call Center, Shopping, etc.
                        </p>
                    </div>
                </div>
    
                <div class="card flex-row h-100 mt-4 col-md-6 border  row py-4 ">
                    <div class="col-12 col-md-4">
                        <img class="img-fluid" src="https://api-site-mig.sotreq.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBaGdFIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--546d240dcfa21d085ab049341e3b752b591dcc1a/energia-varejo.png" />
                    </div>
                    <div class="col-12 col-md-8">
                        <h5 class="mt-4 text-center">Varejo</h5>
    
                        <p style="" class="text-center grey-text">
                            Indústrias, comércio, condomínios, construtoras, instaladoras, locadoras, etc.
                        </p>
                    </div>
                </div>
            </div>
    
            <!-- fim primeiro cands horizontais -->
    
            <div class="card-deck d-flex row flex-md-row flex-md-col flex-wrap">
                <!-- primeiro grid cards -->
                <div class="card h-100 mt-4 col-md-4 border py-4">
                    <img class="img-fluid" src="https://api-site-mig.sotreq.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBaFlFIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--bdd87a9fa34094cdfde4af5c86898bd160155aaa/grupo-gerador-c33.png" />
    
                    <h4 class="mt-4 text-left text-uppercase">
                        GRUPO GERADOR <br /> DIESEL C3.3
                    </h4>
    
                    <p style="" class="my-3 text-left card-text grey-text">
                        Stand-by (kVA/kWe)<br /> 27,0 ekW<br /><br /> Prime (kVA/kWe)<br /> 60,0 ekW<br /><br /> Modelo do motor<br /> Cat® 3.3
                    </p>
                    <div class="mt-4 text-left">
                        <a href="https://sotreqenergia.com.br/produto/de33-gc">
                  Acessar página
                </a>
                    </div>
                </div>
    
                <div class="card h-100 mt-4 col-md-4 border py-4">
                    <img class="img-fluid" src="https://api-site-mig.sotreq.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBaHdFIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--bb5e8def946925bd20cc9244ce050f3da07e1d73/gerador-c15.png " />
    
                    <h4 class="mt-4 text-left text-uppercase">
                        GRUPO GERADOR <br /> DIESEL C15
                    </h4>
    
                    <p style="" class="my-3 text-left card-text grey-text">
                        Stand-by (kVA/kWe)<br /> 500/400 ekW<br /><br /> Prime (kVA/kWe)<br /> 456/365 ekW<br /><br /> Modelo do motor<br /> Cat® C15 ACERTTM
                    </p>
                    <div class="mt-4 text-left">
                        <a href="https://sotreqenergia.com.br/produto/c15-a-diesel">
                  Acessar página
                </a>
                    </div>
                </div>
    
                <div class="card h-100 mt-4 col-md-4 border py-4">
                    <img class="img-fluid" src="https://api-site-mig.sotreq.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBaDBFIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--bc3bfea01601104fc82a941ad652b50f879bae22/energia-c18.png " />
    
                    <h4 class="mt-4 text-left text-uppercase">
                        GRUPO GERADOR <br />DIESEL C18
                    </h4>
    
                    <p style="" class="my-3 text-left card-text grey-text">
                        Stand-by (kVA/kWe)<br /> 750/600 ekW<br /><br /> Prime (kVA/kWe)<br /> 681/545 ekW<br /><br /> Modelo do motor<br /> Cat® C18 ATAACTM
                    </p>
                    <div class="mt-4 text-left">
                        <a href="https://sotreqenergia.com.br/produto/c18-a-diesel">
                  Acessar página
                </a>
                    </div>
                </div>
            </div>
            <!-- fecha primeiro grid -->
            <div class="my-5 text-left">
                <h5 class="font-weight-bold">Conheça todos os nossos geradores a diesel</h5>
                <a href="https://sotreqenergia.com.br/geradores-33-938-kva" style="font-size: 1.3em">
                 33 - 938 kVA
                </a><br>
                <a href="https://sotreqenergia.com.br/geradores-1000-5000-kva" style="font-size: 1.3em">
                  1000 - 5000 kVA
                </a>
            </div>
            <LineTextBlock :data="this.destaques.slice(2, 3)" />
    
            <!-- segundo cards horizontais -->
    
            <div class="card-deck">
                <!-- primeiro grid cards -->
                <div class="card py-4  mt-4 col-md-4 border">
                    <img class="mx-auto" src="https://api-site-mig.sotreq.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBaGtFIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--f27b2720b78c7cdf44788c93560e355119daf074/energia-bioeletricidade.png" style="width:65px"
                    />
                    <h5 class="mt-4 text-center">Bioeletricidade</h5>
    
                    <p style="" class="my-3 text-center grey-text">
                        Biogás com aplicações em suinocultura, aterros sanitários, vinhaças, entre outros.
                    </p>
                </div>
    
                <div class="card py-4 border  mt-4 col-md-4">
                    <img class="mx-auto" src="https://api-site-mig.sotreq.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBaG9FIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--24f2ed8097e1f756c62bcfb6ed72cc3e272e1529/energia-gn.png " style="width:65px"
                    />
                    <h5 class="mt-4 text-center">Geração com GN</h5>
    
                    <p style="" class="my-3 text-center grey-text">
                        Plantas de operação contínua e em horário de ponta, com usinas de cogeração.
                    </p>
                </div>
    
                <div class="card py-4  mt-4 col-md-4 border">
                    <img class="mx-auto" src="https://api-site-mig.sotreq.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBaHNFIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--e45b59adc769923637292fbc7db7e5ce051865ed/energia-distribuida.png" style="width:65px"
                    />
                    <h5 class="mt-4 text-center">Geração distribuída</h5>
    
                    <p style="" class="my-3 text-center grey-text">
                        Usinas térmicas e leilões de energia
                    </p>
                </div>
            </div>
            <div class="card-deck d-flex row flex-md-row flex-md-col flex-wrap">
                <!-- segundo grid cards -->
                <div class="card py-4 h-100 mt-4 col-md-4 border">
                    <img class="img-fluid" src="https://api-site-mig.sotreq.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBaDRFIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--382a499aec486476f86e92e38af0e3ebf8d6b1ce/energia-cg170-20.png" />
    
                    <h4 class="mt-4 text-left text-uppercase">
                        GRUPO GERADOR A GÁS E <br />BIOGÁS CG170-20
                    </h4>
    
                    <p style="" class="my-3 text-left card-text grey-text">
                        Continuous (kVA/kWe)<br /> 2.500/2.000 kVA/kWe<br /><br /> Tipo de combustível<br /> Gás natural, biogás e gás de carvão<br /><br /> Modelo do motor<br /> CG 170-20
                    </p>
                    <div class="mt-4 text-left">
                        <a href="https://sotreqenergia.com.br/produto/cg170-20-a-gas-biogas"> Acessar página </a>
                    </div>
                </div>
    
                <div class="card py-4  h-100 mt-4 col-md-4 border">
                    <img class="img-fluid" src="https://api-site-mig.sotreq.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBaDhFIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--691dc2ab3d8399d7a8a50ead30e6ff3da4b3b900/energia-cg170-12.png " />
    
                    <h4 class="mt-4 text-left text-uppercase">
                        GRUPO GERADOR A GÁS E <br /> BIOGÁS CG170-12
                    </h4>
    
                    <p style="" class="my-3 text-left card-text grey-text">
                        Continuous (kVA/kWe)<br /> 1.500/1.200 kVA/kWe<br /><br /> Tipo de combustível<br /> Gás natural, biogás e gás de carvão<br /><br /> Modelo do motor<br /> CG 170-12
                    </p>
                    <div class="mt-4 text-left">
                        <a href="https://sotreqenergia.com.br/produto/cat-cg170-12-a-gas-biogas"> Acessar página </a>
                    </div>
                </div>
            </div>
            <!-- fecha segundo grid -->
            <div class="my-3 text-left">
                <a href="https://sotreqenergia.com.br/grupo-geradores-gas-bio-gas" style="font-size:1.3em">Conheça todos os nossos geradores a gás/biogás</a>
            </div>
            <LineTextBlockExtended :data="this.destaques.slice(3, 4)" />
            <LineTextBlockExtendedText :data="this.destaques.slice(4, 6)" />
            <!-- <LineTextBlock :data="this.destaques.slice(5, 6)" /> -->
            <div v-if="this.conteudo.length > 1">
                <div class="resetforcontent">
                    <div v-html="this.conteudo"></div>
                </div>
            </div>
            <!--
                    <div class="d-flex justify-content-start mt-5">
         <a :href="textoBaseTexto"
                ><button type="button" class="btn btn-lg">
                  {{ this.textoBaseTitulo }}
                </button></a
              >
              </div>
        <CardsFiltragem  :data="this.cards" />
    
          <div class="row">
            <div class="col mt-4 text-left">
              <h1 class="ml-0 pl-0 mt-2 font-weight-bold">
                {{ this.destaqueTitulo }}
              </h1>
              <p style="white-space: pre-line" class="mt-3">
                {{ this.destaqueTexto }}
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-6 mx-auto">
              <img :src="bottomHeroImage" class="img-fluid mb-5" />
              <a :href="heroBottomLink"
                ><button type="button" class="btn btn-lg mt-5">
                  {{ this.bottomherobuttontext }}
                </button></a
              >
            </div>
          </div>
          -->
          <br><br>
          <widecard />
        </div>
        <Footer  :buttonless="true" />
    </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import HeroMercados from '@/components/Mercados/HeroMercados.vue'
import LineTextBlock from '@/components/shared/LineTextBlock.vue'
import LineTextBlockExtended from '@/components/shared/LineTextBlockExtended.vue'
import LineTextBlockExtendedText from '@/components/shared/LineTextBlockExtendedText.vue'
import CardsFiltragem from '@/components/CardsFiltragem.vue'
import Footer from '@/components/Footer.vue'
import ApiContent from '@/services/api.js'
import Widecard from '../../components/Mercados/widecard.vue'

export default {
    name: 'Home',
    mounted() {
        this.loadContent()
    },
    components: {
        Header,
        HeroMercados,
        LineTextBlock,
        LineTextBlockExtended,
        LineTextBlockExtendedText,
        CardsFiltragem,
        Footer,
        Widecard
    },
    methods: {
        async loadContent() {
            const resp = await ApiContent.get('paginas/5.json')
            console.log(resp.data)
            this.heroTopTitulo = resp.data.heroTopTitulo
            this.heroTopTexto = resp.data.heroTopTexto
            this.heroTopLink = resp.data.heroTopLink
            this.destaqueTitulo = resp.data.destaqueTitulo
            this.destaqueTexto = resp.data.destaqueTexto
            this.destaqueChamada = resp.data.destaqueChamada
            this.textoBaseTitulo = resp.data.textoBaseTitulo
            this.textoBaseTexto = resp.data.textoBaseTexto
            this.heroBottomTitulo = resp.data.heroBottomTitulo
            this.heroBottomTexto = resp.data.heroBottomTexto
            this.heroBottomLink = resp.data.heroBottomLink
            this.bottomherobuttontext = resp.data.bottomherobuttontext
            this.heroBottomLink = resp.data.heroBottomLink
            this.url = resp.data.url
            this.topHeroImage = resp.data.topHeroImage
            this.logo = resp.data.logo
            this.bottomHeroImage = resp.data.bottomHeroImage
            this.cards = resp.data.cards
            this.destaques = resp.data.destaques
            this.conteudo = resp.data.content
            this.bannermercado = {
                titulo: resp.data.heroTopTitulo,
                image: resp.data.topHeroImage,
                subtitulo: resp.data.subtitulo,
                texto: resp.data.texto,
                link: resp.data.link,
                buttontext: resp.data.buttontext,
                herocontent: resp.data.herocontent
            }
        }
    },
    data() {
        return {
            heroTopTitulo: [],
            heroTopTexto: [],
            heroTopLink: [],
            destaqueTitulo: [],
            destaqueTexto: [],
            destaqueChamada: [],
            textoBaseTitulo: [],
            textoBaseTexto: [],
            heroBottomTitulo: [],
            heroBottomTexto: [],
            heroBottomLink: [],
            created_at: [],
            updated_at: [],
            url: [],
            topHeroImage: [],
            logo: [],
            bottomHeroImage: [],
            cards: [],
            destaques: [],
            conteudo: []
        }
    }
}
</script>

<style scoped>
.quadrado {
    border: 5px solid #ffcb11;
}

.equipamento_nvo {
    background: rgb(2, 0, 36);
    background: radial-gradient( circle, rgba(2, 0, 36, 1) 0%, rgba(214, 155, 31, 1) 50%);
}

a {
    text-decoration: none;
}

button {
    border: 2px solid white;
    background-color: black;
    color: white;
}

button:hover {
    border: 2px solid black;
    background-color: white;
    color: black;
}
</style>
