<template>
  <div class="home">
    <Header />
    <HeroMercados :data="bannermercado" />
    <div class="container">

             <div class="row">
                        <div class="col-12 mt-4 text-left">
                            <h2 class="ml-0 pl-0 mt-2 font-weight-bold">{{ destaqueTitulo }}</h2>
                            <p class="mt-3" style="white-space: pre-line;">
                                Conheça a última solução em suporte técnico especializado à distância para diagnósticos de falhas em equipamentos Cat®, por meio de óculos de realidade aumentada.
                            </p>
                            <h3 class="ml-0 pl-0 mt-2 font-weight-bold">
                                O mecânico em campo realiza o seu trabalho enquanto recebe orientações de um especialista de fábrica em tempo real.
                            </h3>
                        </div>
                    </div>
                    <div class="row my-3 p-3 tele-engenharia-bg-dark d-flex align-content-middle">
                        <div class="col-lg-8 col-md-12 col-sm-12 col-12">
                            <h3 class="text-left">Vantagens em contratar o serviço</h3>
                            <div class="bar mt-1"></div>
                            <ul>
                                <li class="text-left">Tecnologia inovadora permite resolver problemas de média e alta complexidade;</li>
                                <li class="text-left">Acesso a especialistas com conhecimento completo de máquinas Cat;</li>
                                <li class="text-left">Reduz tempo de suporte técnico;</li>
                                <li class="text-left">Maximiza a performance dos seus ativos e dos contratos;</li>
                                <li class="text-left">Mais disponibilidade física.</li>
                            </ul>
                        </div>
  <div class="col-lg-4 col-md-12 col-sm-12 col-12">
                            <p>
                                <img src="https://api-site-mig.sotreq.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBbEFHIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--94f526b6e9a0d14fbe723d0777db75eeb97b66c2/tele-engenharia-operador.png" alt="Fotografia de um Teleoperador" class="img-fluid">
                            </p>
                        </div>
</div>
 <div class="container tele-engenharia pt-2">
                    <div class="row mb-4">
                        <div class="col-12">
                            <h3 class="fw-bold-custom">Suporte técnico onde e quando você precisar</h3>
                            <p class="mb-0 sz-24">Conheça como a realidade aumentada pode aprimorar o processo de solução de problemas na sua operação. Assista ao vídeo e veja a última solução de suporte técnico à distância.</p>
                        </div>
                    </div>
                    <div class="row mb-5">
                        <div class="col-12">
                            <div style="position: relative; padding-bottom: 56.25%; /* proporção 16:9 */ height: 0; overflow: hidden;">
                                <iframe src="https://www.youtube.com/embed/-u4v6NXrdmo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"></iframe>
                            </div>
                        </div>
                    </div>




    </div>
               <div class="row justify-content-center">
                      
                            <h2 class="font-weight-bold">Preencha seus dados que a Sotreq entra em contato com você!</h2>
                            <p class="sz-24">Preencha o formulário abaixo e entraremos em contato com você.</p>
   </div>    </div>
    <Footer bgcolor="#f5f5f5" :buttonless="true" :hasform="true" gtmterm="pagina_tele_engenharia" />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import HeroMercados from '@/components/Mercados/HeroMercados.vue'
import LineTextBlock from '@/components/shared/LineTextBlock.vue'
import CardsFiltragem from '@/components/CardsFiltragem.vue'
import Footer from '@/components/Footer.vue'
import ApiContent from '@/services/api.js'

export default {
  name: 'Locker',
  mounted () {
    this.loadContent()
  },
  components: {
    Header,
    HeroMercados,
    LineTextBlock,
    CardsFiltragem,
    Footer
  },
  methods: {
    async loadContent () {
      const resp = await ApiContent.get('paginas/188.json')
      console.log(resp.data)
      this.heroTopTitulo = resp.data.heroTopTitulo
      this.heroTopTexto = resp.data.heroTopTexto
      this.heroTopLink = resp.data.heroTopLink
      this.destaqueTitulo = resp.data.destaqueTitulo
      this.destaqueTexto = resp.data.destaqueTexto
      this.destaqueChamada = resp.data.destaqueChamada
      this.textoBaseTitulo = resp.data.textoBaseTitulo
      this.textoBaseTexto = resp.data.textoBaseTexto
      this.heroBottomTitulo = resp.data.heroBottomTitulo
      this.heroBottomTexto = resp.data.heroBottomTexto
      this.heroBottomLink = resp.data.heroBottomLink
      this.bottomherobuttontext = resp.data.bottomherobuttontext
      this.heroBottomLink = resp.data.heroBottomLink
      this.url = resp.data.url
      this.topHeroImage = resp.data.topHeroImage
      this.logo = resp.data.logo
      this.bottomHeroImage = resp.data.bottomHeroImage
      this.cards = resp.data.cards
      this.destaques = resp.data.destaques

      this.conteudo = resp.data.content
      this.bannermercado = {
        titulo: resp.data.heroTopTitulo,
        image: resp.data.topHeroImage,
        subtitulo: resp.data.subtitulo,
        texto: resp.data.texto,
        link: resp.data.link,
        buttontext: resp.data.buttontext,
        herocontent: resp.data.herocontent
      }
    }
  },
  data () {
    return {
      heroTopTitulo: [],
      heroTopTexto: [],
      heroTopLink: [],
      destaqueTitulo: [],
      destaqueTexto: [],
      destaqueChamada: [],
      textoBaseTitulo: [],
      textoBaseTexto: [],
      heroBottomTitulo: [],
      heroBottomTexto: [],
      heroBottomLink: [],
      created_at: [],
      updated_at: [],
      url: [],
      topHeroImage: [],
      logo: [],
      bottomHeroImage: [],
      cards: [],
      destaques: [],
      conteudo: []
    }
  }
}
</script>
<style scoped>
.quadrado {
  border: 5px solid #ffcb11;
}
.equipamento_nvo {
  background: rgb(2, 0, 36);
  background: radial-gradient(
    circle,
    rgba(2, 0, 36, 1) 0%,
    rgba(214, 155, 31, 1) 50%
  );
}
a {
  text-decoration: none;
}
button {
  border: 2px solid white;
  background-color: black;
  color: white;
}

button:hover {
  border: 2px solid black;
  background-color: white;
  color: black;
}
.btn-black {
    border: 2px solid #fff;
    background-color: #000;
    color: #fff
}

.btn-black:hover {
    border: 2px solid #000;
    background-color: #fff;
    color: #000
}

.notalink {
    text-decoration: none;
    color: unset !important
}

.notalink:hover {
    text-decoration: underline;
    color: unset !important
}

.sotreqlink-btn {
    color: #ffc107 !important
}
.tele-engenharia-bg-dark {
    background-color: #252525;
    color: #fff;
}

.tele-engenharia-bg-dark ul {
    padding-left: 20px;
    margin-top: 1rem;
}
.tele-engenharia-bg-dark[data-v-79e26dce] {
  align-items: center;
}
.fw-bold-custom {
    font-weight: 700 !important
}
.sz-24 {
    font-size: 24px !important;
}
.footer-gray {
    margin-top: -69px;
}
.row.my-3.p-3.tele-engenharia-bg-dark.d-flex.align-content-middle {
    align-items: center;
}
</style>  
