<template>
  <div class="text-left card-body p-0">
    <div class="col mt-4 text-left p-0">
      <h3 class="ml-0 p-0 mb-2">ESCOPO</h3>
    </div>
    <p style="white-space: pre-line">
      O escopo do sistema de gestão destina-se a esclarecer os limites físicos e
      organizacionais aos quais se aplica o sistema de gestão. É uma declaração
      real e representativas das operações da Sotreq que estão incluídas nos
      limites do Sistema de Gestão Integrado. Deve estar disponível para as
      partes interessadas.
    </p>
    <br />
    <h5>Alto Horizonte</h5>

    <p>
      Filial: Serviços administrativos, vendas de peças, serviços e
      armazenamento de material desempenhadas pelo almoxarifado. Contrato Marc:
      Atividades de manutenção preventiva e corretiva na frota CAT, de acordo
      com as demandas existentes pela frota do cliente Mineração Maracá.
    </p>

    <h5>{{ data[0].name }}</h5>
    <p>
      Comércio atacadista de máquinas e equipamentos (incluindo comércio
      atacadista de suas partes e peças) para: terraplenagem, mineração e
      construção. Corte e montagem de mangueiras hidráulicas flexíveis para
      essas máquinas e equipamentos. <br />
      Wholesale of machinery and equipment (including wholesale trade of parts
      and pieces) for: earthmoving, mining and construction. Cutting and
      assembly of hydraulic hoses flexible for these machines and equipment.
      <br /><br />
      <!--  <a href="https://api-site-mig.sotreq.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBbkVHIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--a713c9ee41330efa0af8b72564f6382cbf78cfeb/FO-SGI-2011.4%20-%20Avalia%C3%A7%C3%A3o%20de%20desempenho%20-%2008.2023%201.xlsx" target="_blank"> -->
      <a :href="data[0].arquivo" target="_blank">
        Avaliação de Desempenho do SGI</a
      >
    </p>
    <h5>Contagem</h5>
    <p>
      Comércio atacadista de máquinas e equipamentos para terraplanagem,
      mineração e construção, serviços de manutenção em máquinas e seus
      componentes, exceto serviços externos. <br />
      Wholesales machinery and equipment earthwork, mining and construction,
      services for maintenance in machinery and components, excluding field
      services.
    </p>
    <h5>Contagem – Laboratório SOS</h5>
    <p>
      Análise físico-química de óleos lubrificantes, diesel e de líquidos
      arrefecedores e a interpretação de seus resultados. <br />
      Physico-chemical analysis for lubricating oils, diesel and coolant and
      technical interpretation of their results.
    </p>
    <h5>{{ data[1].name }}</h5>
    <p>
      Serviços de remanufatura de componentes e subconjuntos de equipamentos
      pesados, compreendendo serviços em: hidráulica, trem de força, motores e
      fast service. <br />Remanufacturing services for heavy equipment
      components and sub–assemblies, including services on: hydraulic, power
      train, engines and fast service. <br /><br />
      <a :href="data[1].arquivo" target="_blank">
        Avaliação de Desempenho do SGI</a
      >
    </p>
    <h5>{{ data[2].name }}</h5>

    <p>
      Suporte as atividades de comercialização de máquinas e equipamentos para
      os segmentos de construção, mineração, energia, petróleo e marítimo.
      <br />
      Support for activities for selling machinery and equipment for the
      segments of Construction, Mining, Energy, Petroleum and Marine.
      <br /><br />
      <a :href="data[2].arquivo" target="_blank">
        Avaliação de Desempenho do SGI</a
      >
    </p>
    <h5>{{ data[3].name }}</h5>

    <p>
      Comércio atacadista de equipamentos, componentes, peças e serviços de
      manutenção para o segmento de petróleo e marítimo exceto serviços externos
      de campo. <br />
      Wholesaler equipments, components, parts and services for maintenance for
      the segments of Petroleum and Marine, except extern services. <br /><br />
      <a :href="data[3].arquivo" target="_blank">
        Avaliação de Desempenho do SGI</a
      >
    </p>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "collapsesustentabilidade",
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
};
</script>
<style scoped>
button {
  text-decoration: none;
}
.title-btn {
  text-transform: uppercase;
}
/* button {
  border: 2px solid white;
  background-color: black;
  color: white;
}

button:hover {
  border: 2px solid black;
  background-color: white;
  color: black;
} */
</style>
