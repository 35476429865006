<template>
  <div class="home">
    <Header />
    <HeroMercados :data="bannermercado" />
    <div class="container">
      <div class="row my-5">
        <div class="col text-left">
          <h2 class="font-weight-bold">{{ destaqueTitulo }}</h2>
          <div class="bar mb-5"></div>
          <p class="preline">{{ destaqueTexto }}</p>
        </div>
      </div>
      <h1 class="card-title text-yellow text-left mb-5"> {{textoBaseTitulo }} </h1>
      <div class="d-none d-md-none d-lg-block d-xl-block">
        <div class="row">
          <div class="col-md-7">
            <LineTextBlock :data="this.destaques" />
          </div>
          <div class="col-md-5">
            <img
              :src="logo"
              class="img-fluid"
              alt="Sotreq Retiraq Locker"
            />
            <img
              :src="bottomHeroImage"
              class="img-fluid"
              alt="Sotreq Retiraq Locker"
            />
          </div>
        </div>
      </div>
      <div class="d-block d-sm-block d-md-block d-lg-none">
        <div class="row">
          <div class="col-md-7">
            <div class="row">
              <div class="col text-left">
                <h2 class="font-weight-bold">{{ destaques[0].titulo }}</h2>
                <div class="bar mb-3"></div>
                <p class="preline">{{ destaques[0].texto }}</p>
              </div>
            </div>
          </div>
          <div class="col-md-5">
            <img
              :src="topHeroImage"
              class="img-fluid"
              alt="Sotreq Retiraq Locker"
            />
            <img
              :src="bottomHeroImage"
              class="img-fluid"
              alt="Sotreq Retiraq Locker"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-7">
            <div class="col text-left">
              <h2 class="font-weight-bold">{{ destaques[1].titulo }}</h2>
              <div class="bar mb-3"></div>
              <p class="preline">{{ destaques[1].texto }}</p>
            </div>
            <div class="col text-left">
              <h2 class="font-weight-bold">{{ destaques[2].titulo }}</h2>
              <div class="bar mb-3"></div>
              <p class="preline">{{ destaques[2].texto }}</p>
            </div>
            <div class="col text-left">
              <h2 class="font-weight-bold">{{ destaques[3].titulo }}</h2>
              <div class="bar mb-3"></div>
              <p class="preline">{{ destaques[3].texto }}</p>
            </div>
          </div>
        </div>
        <div class="col-md-5"></div>
      </div>
      <div class="embed-responsive embed-responsive-16by9">
        <iframe
          :src="destaqueChamada"
          title="Sotreq Retiraqui"
          class="embed-responsive-item"
          allowfullscreen
        ></iframe>
      </div>
      <div class="webchat-widget"></div>
<script src="https://sotreq-ims.jcr.net.br:3006/webchat/widget/module.js" type="module"></script>
<link href="https://sotreq-ims.jcr.net.br:3006/webchat/widget/module.css" rel="stylesheet"/>

      
      <div class="d-flex flex-column justify-content-start mt-5">
      <h1 class="card-title text-yellow text-left mb-5"> {{textoBaseTitulo }} </h1>
      <p class="text-left"> {{ textoBaseTexto }} </p>
      </div>[
      <CardsFiltragem :data="this.cards" :titleless="true"/>
    </div>
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import HeroMercados from '@/components/Mercados/HeroMercados.vue'
import LineTextBlock from '@/components/shared/LineTextBlock.vue'
import CardsFiltragem from '@/components/CardsFiltragem.vue'
import Footer from '@/components/Footer.vue'
import ApiContent from '@/services/api.js'

export default {
  name: 'Cilindros',
  mounted () {
    this.loadContent()
  },
  components: {
    Header,
    HeroMercados,
    LineTextBlock,
    CardsFiltragem,
    Footer
  },
  methods: {
    async loadContent () {
      const resp = await ApiContent.get('paginas/179.json')
      console.log(resp.data)
      this.heroTopTitulo = resp.data.heroTopTitulo
      this.heroTopTexto = resp.data.heroTopTexto
      this.heroTopLink = resp.data.heroTopLink
      this.destaqueTitulo = resp.data.destaqueTitulo
      this.destaqueTexto = resp.data.destaqueTexto
      this.destaqueChamada = resp.data.destaqueChamada
      this.textoBaseTitulo = resp.data.textoBaseTitulo
      this.textoBaseTexto = resp.data.textoBaseTexto
      this.heroBottomTitulo = resp.data.heroBottomTitulo
      this.heroBottomTexto = resp.data.heroBottomTexto
      this.heroBottomLink = resp.data.heroBottomLink
      this.bottomherobuttontext = resp.data.bottomherobuttontext
      this.heroBottomLink = resp.data.heroBottomLink
      this.url = resp.data.url
      this.topHeroImage = resp.data.topHeroImage
      this.logo = resp.data.logo
      this.bottomHeroImage = resp.data.bottomHeroImage
      this.cards = resp.data.cards
      this.destaques = resp.data.destaques
      this.conteudo = resp.data.content
       this.bannermercado = {
        titulo: resp.data.heroTopTitulo,
        image: resp.data.topHeroImage,
        subtitulo: resp.data.subtitulo,
        texto: resp.data.texto,
        link: resp.data.link,
        buttontext: resp.data.buttontext,
        herocontent: resp.data.herocontent
};
    },
  },
  data () {
    return {
      heroTopTitulo: [],
      heroTopTexto: [],
      heroTopLink: [],
      destaqueTitulo: [],
      destaqueTexto: [],
      destaqueChamada: [],
      textoBaseTitulo: [],
      textoBaseTexto: [],
      heroBottomTitulo: [],
      heroBottomTexto: [],
      heroBottomLink: [],
      created_at: [],
      updated_at: [],
      url: [],
      topHeroImage: [],
      logo: [],
      bottomHeroImage: [],
      cards: [],
      destaques: [],
      conteudo: [],
      bannermercado: []
    }
  }
}
</script>
<style scoped>
.quadrado {
  border: 5px solid #ffcb11;
}
.equipamento_nvo {
  background: rgb(2, 0, 36);
  background: radial-gradient(
    circle,
    rgba(2, 0, 36, 1) 0%,
    rgba(214, 155, 31, 1) 50%
  );
}
a {
  text-decoration: none;
}
button {
  border: 2px solid white;
  background-color: black;
  color: white;
}

button:hover {
  border: 2px solid black;
  background-color: white;
  color: black;
}
</style>
